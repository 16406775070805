import { QueryFunctionContext } from "react-query";
import {
  BoardCompositionModel,
  BoardMeetingModel,
} from "../types/BoardMeetingsModels";
import api from "./capTableApi";

export type MeetingDocTypes = {
  id: string;
  companyId: string;
  documentType: DocumentType;
  cin: string;
  name: string;
  key: string;
  size: string;
  tags: string[];
  updatedAt: string;
  meetingId: string;
};

export async function getBoardMeetingDetails(): Promise<any> {
  return api
    .get(`/v1/company/companyBoardMeetings/`)
    .then((res) => res.data.data);
}

export async function getInvBoardMeetingDetails(
  context: QueryFunctionContext
): Promise<any> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get("v1/company/inv/companyBoardMeetings", {
      params: { companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function addBoardMeetingDetails(
  configData: BoardMeetingModel
): Promise<any> {
  return api
    .post("/v1/company/companyBoardMeetings/save", configData)
    .then((res) => res.data);
}

export async function removeMeetingDetails({
  id,
}: {
  companyId: string;
  id: string;
}): Promise<string> {
  return api
    .delete(`/v1/company/companyBoardMeetings/delete`, {
      data: { id },
    })
    .then((res) => res.data.data.id);
}

export async function getBoardCompositionDetails(): Promise<any> {
  return api
    .get(`/v1/company/companyBoardMeetings/getBoardComposition`)
    .then((res) => res.data.data);
}

export async function addBoardCompositionDetails(
  configData: BoardCompositionModel
): Promise<any> {
  return api
    .post("/v1/company/companyBoardMeetings/saveBoardComposition", configData)
    .then((res) => res.data);
}

export async function getMeetingDocuments(
  context: QueryFunctionContext
): Promise<MeetingDocTypes[]> {
  const meetingId = context.queryKey[1] as string;
  const companyId = context.queryKey[2] as string;
  return api
    .get("v1/company/companyBoardMeetings/documents", {
      params: { meetingId },
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export async function uploadMeetingDocuments({
  companyId,
  formData,
}: {
  companyId: string;
  formData: FormData;
}): Promise<string> {
  return api
    .post(`v1/company/companyBoardMeetings/document`, formData, {
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data.id);
}

export async function removeMeetingDocument({
  companyId,
  meetingId,
  documentId,
}: {
  companyId: string;
  documentId: string;
  meetingId: string;
}): Promise<string> {
  return api
    .delete(`v1/company/companyBoardMeetings/document`, {
      params: { meetingId, documentId },
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data.id);
}
