import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  BoxContainer,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  CompanyDetailsPostModel,
  ValuationDataModel,
} from "../../types/OnBoardDataModel";

import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { initialValuationData } from "./initValues";
import AddEditvaluation from "../../modals/AddEditValuationDetails";
import { formatDisplayDate } from "../../utils/date";
import { Headings } from "../../components/Headings";
import { Description } from "../../components/shared/InputField";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";

export default function ValuationT() {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof ValuationDataModel | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: true });
  const [dialog, setDialog] = useState<{
    message?: string;
    open: boolean;
    valuation?: ValuationDataModel;
    mode?: "Edit" | "Add" | "Delete" | "Bulk Delete";
    index?: number;
  }>({
    open: false,
    mode: "Add",
  });

  const [tempvaluationDetails, setTempvaluationDetails] =
    useState<ValuationDataModel[]>();

  const [selectedItems, setSelectedItems] = useState<ValuationDataModel[]>([]);
  const onSelectionChange = (valuation: ValuationDataModel) =>
    setSelectedItems((valuations) =>
      valuations.find(
        (individualvaluation) => individualvaluation === valuation
      )
        ? valuations.filter(
            (individualvaluation) => individualvaluation !== valuation
          )
        : [...valuations, valuation]
    );

  function handleSecondaryAction() {
    setFieldValue("valuationDetails", tempvaluationDetails);
  }

  function handleAction(action: Action, valuation?: ValuationDataModel) {
    setTempvaluationDetails(formik.values.valuationDetails);
    const index = formik.values.valuationDetails.findIndex(
      (individualvaluation) => individualvaluation === valuation
    );
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        valuation,
        message: "",
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
        valuation,
        message: "",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        valuation,
        message: "Do you want to delete this valuation Details?",
      });
    } else if (action.name === "Bulk Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Bulk Delete",
        valuation,
        message: "Do you want to delete selected valuation Details?",
      });
    }
  }

  const deleteAllvaluation = () => {
    if (selectedItems.length === 0) return;
    const valuations = selectedItems;
    setFieldValue(
      "valuations",
      values?.valuationDetails?.filter(
        (valuation) => !valuations?.includes(valuation)
      )
    );
    handleAction(
      {
        name: "Bulk Delete",
        disabled: false,
      },
      valuations[0]
    );
    toast("valuation Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
    setSelectAllChecked(false);
  };

  function deleteParticularvaluation(valuation?: ValuationDataModel) {
    setFieldValue(
      "valuations",
      values?.valuationDetails?.filter(
        (individualvaluation) => individualvaluation !== valuation
      )
    );
    toast("valuation Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  }

  function handleAdd(index: number) {
    const valuations = [...values.valuationDetails];
    valuations.splice(index, 0, initialValuationData);
    setFieldValue("valuationDetails", valuations);
  }

  return (
    // <div className="w-full border-borderColor border mt-10 bg-white rounded-md shadow-box">
    // <Box className="w-full sm:px-10 sm:py-4 p-4  bg-white rounded-lg min-w-min">
    //   <HStack
    //     aria-label="toolbar"
    //     className="justify-between mb-8 sm:flex-row flex-col"
    //   >
    <BoxContainer>
      <HStack
        aria-label="toolbar"
        className="justify-between mb-8 sm:flex-row flex-col"
      >
        <HStack className="justify-between">
          <Headings
            text="Valuation Details"
            subText={`${values.valuationDetails?.length} results`}
          />
          <HStack>
            <Center className="p-2 px-3 mx-2   sm:hidden block rounded text-slate-dark bg-slate-light  border border-borderColor">
              <Tooltip text="Delete">
                <Icon
                  className="cursor-pointer "
                  icon="fluent:delete-28-regular"
                  color={selectedItems.length === 0 ? "gray" : "#E85936"}
                  onClick={() => {
                    setDialog({ open: true });
                    handleAction(
                      {
                        name: "Bulk Delete",
                        disabled: false,
                      },
                      undefined
                    );
                  }}
                  width="20"
                  height="24"
                />
              </Tooltip>
            </Center>
            <PrimaryCTAButton
              event-name="Add Valuation"
              className="min-w-max sm:hidden block"
              onClick={() => {
                handleAdd(values.valuationDetails?.length);
                handleAction({
                  name: "Add",
                  disabled: false,
                });
              }}
            >
              Add
            </PrimaryCTAButton>
          </HStack>
        </HStack>
        <HStack className="h-8 min-w-min sm:mt-0 gap-4 mt-2">
          <Tooltip text="Delete">
            <IconCTAButton
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setDialog({ open: true });
                handleAction(
                  {
                    name: "Bulk Delete",
                    disabled: false,
                  },
                  undefined
                );
              }}
              iconName={"fluent:delete-24-regular"}
              className={`py-2`}
            />
          </Tooltip>

          <HStack className="items-center p-2 rounded w-full bg-transparent text-slate-dark border border-inputBorderFocus">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full font-medium border-none outline-none focus:ring-0 text-xs2 bg-inherit"
              placeholder="Search"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
          <PrimaryCTAButton
            event-name="Add Valuation"
            className="min-w-max sm:block hidden"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              handleAdd(values.valuationDetails?.length);
              handleAction({
                name: "Add",
                disabled: false,
              });
            }}
          >
            Add
          </PrimaryCTAButton>
        </HStack>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Delete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => deleteParticularvaluation(dialog.valuation)}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Edit" ? (
          <AddEditvaluation
            valuationIndex={dialog.index || 0}
            valuation={dialog.valuation}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => {
              handleSecondaryAction();
              setDialog({ open: false });
            }}
            mode="Edit"
          />
        ) : dialog.mode === "Add" ? (
          <AddEditvaluation
            valuationIndex={values.valuationDetails.length - 1}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => {
              handleSecondaryAction();
              setDialog({ open: false });
            }}
            mode="Add"
          />
        ) : (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => deleteAllvaluation()}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
      </Dialog>
      <VStack className="w-full gap-8">
        <table className="w-full table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td className="py-3">
                <input
                  type="checkbox"
                  className="accent-orange-501 outline-hidden cursor-pointer"
                  checked={selectAllChecked}
                  onChange={(e) => {
                    setSelectAllChecked(!selectAllChecked);
                  }}
                ></input>
              </td>

              <>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "name",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Valuation Name
                </td>
              </>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "dateOfValuation",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Date Of Valuation
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "valuationPps",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Valuation PPS
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "bookValue",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Book Value
              </td>
              <td className="py-3"></td>
            </tr>
          </thead>
          {values.valuationDetails && (
            <tbody>
              {values.valuationDetails &&
                values.valuationDetails?.map((valuation, index) => (
                  <tr key={index} className="border-t border-dashed ">
                    <td className="py-5 align-top">
                      <Box>
                        <input
                          type="checkbox"
                          className="accent-orange-501 outline-hidden cursor-pointer  "
                          checked={selectedItems.indexOf(valuation) !== -1}
                          onChange={(e) => onSelectionChange(valuation)}
                        ></input>
                      </Box>
                    </td>

                    <>
                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {valuation.name}
                            </p>
                          </Box>
                        </HStack>
                      </td>
                    </>

                    <td className="py-4 align-top">
                      <Box>
                        <HStack>
                          <p
                            className={` w-32 text-xs font-medium text-gray-dark`}
                          >
                            {formatDisplayDate(valuation.dateOfValuation)}
                          </p>
                        </HStack>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className={` text-xs font-normal text-gray-dark`}>
                          {valuation.valuationPps}
                        </p>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <HStack>
                          <p
                            className={` w-32 text-xs font-medium text-gray-dark`}
                          >
                            {valuation.bookValue}
                          </p>
                        </HStack>
                      </Box>
                    </td>
                    <td className="px-2 py-4 align-top">
                      <CTADropdown
                        dropdownClassName="bottom-auto"
                        actions={[
                          {
                            name: "Edit",
                            disabled: false,
                          },
                          {
                            name: "Delete",
                            disabled: false,
                          },
                        ]}
                        onAction={(action) => handleAction(action, valuation)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {values.valuationDetails?.length <= 0 && (
          <EmptyTableIllustration
            text={" No valuations Data Found. Please Add the valuations"}
          />
        )}

        <Box className="flex justify-between mt-8 cursor-pointer">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          />
          {/* <Pagination
            currentPage={currentPage}
            totalCount={valuationList?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          /> */}
        </Box>
      </VStack>
    </BoxContainer>
  );
}
