import { useParams } from "react-router";
import {
  useGetAllBoardMeetingDetails,
  useGetBoardCompositionDetails,
  useInvGetAllBoardMeetingDetails,
} from "../../queries/boardMeetingDetails";
import {
  useGetDirectorDetails,
  useInvGetDirectorDetails,
} from "../../queries/directorDetails";
import BCHeader from "../../shared/BCHeader";
import { BoardMeetingModel } from "../../types/BoardMeetingsModels";
import { DirectorDetailDataModel } from "../../types/OnBoardDataModel";
import BoardComposition from "../onboarding/BoardComposition";
import BoardMeetingAgTable from "../onboarding/BoardMeetingAgTable";
import DirectorAgTable from "../onboarding/DirectorsAgTable";

export function BoardModuleWrapper() {
  const { cId, token } = useParams() as {
    cId: string;
    token: string;
  };

  const { data } = cId
    ? useInvGetDirectorDetails(cId, token)
    : useGetDirectorDetails();

  const { data: boardMeetingData } = cId
    ? useInvGetAllBoardMeetingDetails(cId, token)
    : useGetAllBoardMeetingDetails();
  // const { data: boardCompositionData, isSuccess } = useGetBoardCompositionDetails();
  return (
    <>
      <div className={`${cId ? "bg-white px-4" : "gap-4 mx-auto"}`}>
        <BCHeader className="items-baseline mb-4" bcTitle="Board Module" />
        <div className="flex flex-col gap-8">
          <DirectorAgTable
            data={(data?.directors as DirectorDetailDataModel[]) || []}
          />
          <BoardMeetingAgTable
            data={boardMeetingData?.boardMeetings as BoardMeetingModel[]}
            directorData={data?.directors as DirectorDetailDataModel[]}
          />
          {/* { isSuccess && <BoardComposition
          boardCompositions={boardCompositionData?.boardCompositions}
          director={(data?.directors as DirectorDetailDataModel[]) || []}
          isData={boardCompositionData?.boardCompositions?.numberOfDirectors !== undefined}/>
          } */}
        </div>
      </div>
    </>
  );
}
