import { tableEntryStyle } from "../../components/TableComponent";
import { VStack } from "../../components/utils";
import { formatDisplayDate } from "../../utils/date";

export function DirectorNameRender(data: any) {
  return (
    <VStack>
      <p className="font-bold">{data.data.directorName}</p>
    </VStack>
  );
}

export function DateOfAppointmentRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.dateOfAppointment)}
      </p>
    </div>
  );
}
export function ResignationDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.resignationDate)}
      </p>
    </div>
  );
}

const colorPalette = [
  "bg-[#241DBA]/[0.05] text-[#241DBA]/[0.7]",
  "bg-[#2DA900]/[0.05] text-[#2DA900]/[0.7]",
  "bg-[#00A3C7]/[0.05] text-[#00A3C7]",
  "bg-[#E79212]/[0.05] text-[#E79212]",
  "bg-[#F86161]/[0.05] text-[#F86161]",
  "bg-[#6904DC]/[0.2] text-[#6904DC]",
  "bg-[#BAA8ED]/[0.05] text-[#D5CBF4]",
  "bg-[#FF9F40]/[0.05] text-[#FF9F40]/[0.7]",
  "bg-[#36A2EB]/[0.05] text-[#36A2EB]/[0.7]",
  "bg-[#FF6384]/[0.05] text-[#FF6384]/[0.7]",
];

const tagsColorMap = new Map<string, string>();

export function getTagsColor(securityType: string, index: number): string {
  if (tagsColorMap.has(securityType)) {
    return tagsColorMap.get(securityType)!;
  }

  const colorIndex = index % colorPalette.length;
  const assignedColor = colorPalette[index];

  tagsColorMap.set(securityType, assignedColor);

  return assignedColor;
}

export function TagsRender(data: any) {
  return (
    <div className="flex flex-nowrap items-center gap-2 overflow-x-auto scrollbar-hide pt-2">
      {data.data.tags?.map((tag: string, index: number) => (
        <div
          key={index}
          className={`${getTagsColor(
            tag,
            index
          )} text-xs text-center px-3 font-semibold leading-5 rounded-md`}
        >
          {tag}
        </div>
      ))}
    </div>
  );
}
