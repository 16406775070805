import _ from "lodash";
import randomColor from "randomcolor";
import { format } from "date-fns";
import {
  DashBoardModel,
  EventComparisonModel,
  HeaderDataModel,
  InvestmentShareHolderModel,
  RoundSummary,
  RoundsBarModel,
  RoundsDetailModel,
} from "../types/DashboardModel";
import { DashboardCardDetails } from "../types/quickRound";
import { sort } from "./arrays";
import { MarkLabel } from "../pages/dashboardPage/DashboardCapTableTable";

export const commonExtractor = (allEventsDetail: DashBoardModel[]) => {
  const allRoundDetails: RoundsDetailModel[] = [];
  const data: RoundsBarModel[] = [];
  const list: string[] = [];
  const shareholder: RoundsBarModel[] = [];
  let count = 0;
  const markLabels: MarkLabel[] = [];
  const nameOfAllRound: string[] = [];
  const tempInvestmentData: InvestmentShareHolderModel[] = [];
  let indexDetails = 0;
  const shareHolderInvestment: EventComparisonModel[] = [];
  const tempShareHoldersDetailName: string[] = [];
  let lastDate = "";
  allEventsDetail?.forEach((element: DashBoardModel) => {
    if (element.type === "round") {
      const groupedData = _.groupBy(element.capTable, "type");
      let shareholderType;
      let arrayDif: string[] = [];
      for (const investorType of Object.keys(groupedData)) {
        shareholderType = {
          name: investorType,
          value: _.sumBy(groupedData[investorType], "fdbPercentage") * 100,
        };
        if (list.includes(investorType)) {
          const difference = list.length - Object.keys(groupedData).length;
          arrayDif = arrayDifference(list, Object.keys(groupedData));
          const countIndex = list.findIndex((ele) => ele === investorType) || 0;
          const newPercentage = shareholder[countIndex].percentage;
          newPercentage.push(parseFloat(shareholderType.value.toFixed(2)));
          shareholder[countIndex].percentage = newPercentage;
        } else {
          list.push(investorType);
          const zeroList = Array(count).fill(0);
          zeroList.push(parseFloat(shareholderType.value.toFixed(2)));
          shareholder.push({
            shareholderType: shareholderType.name,
            percentage: zeroList,
          });
        }
      }
      if (arrayDif.length > 0) {
        arrayDif.forEach((element) => {
          const countIndex = list.findIndex((ele) => ele === element) || 0;
          const newPercentage = shareholder[countIndex].percentage;
          newPercentage.push(parseFloat("0"));
          shareholder[countIndex].percentage = newPercentage;
        });
      }
      count += 1;
    }
  });

  allEventsDetail?.forEach((eventDetail, index) => {
    const year = format(new Date(eventDetail.eventDate), "yyyy");
    if (
      index !== 0 &&
      index !== allEventsDetail.length - 1 &&
      index !== Math.floor((allEventsDetail.length - 1) / 4) &&
      index !== Math.floor((allEventsDetail.length - 1) / 2) &&
      index !== Math.floor(((allEventsDetail.length - 1) / 4) * 3)
    ) {
      markLabels.push({
        value: index,
        label: "",
      });
    } else {
      lastDate = year;
      markLabels.push({
        value: index,
        label: lastDate,
      } as MarkLabel);
    }

    if (
      eventDetail.roundSummary.type === "round" ||
      eventDetail.roundSummary.type === "secondary"
    ) {
      nameOfAllRound.push(eventDetail.roundSummary.name);
      if (eventDetail.roundSummary.type === "round")
        allRoundDetails.push({
          roundName: eventDetail.roundSummary.name,
          holding: shareholder,
          date: eventDetail.roundSummary.date,
          dilution: parseFloat(
            ((eventDetail.roundSummary.dilution || 0) * 100).toFixed(2)
          ),
          valution: parseFloat(eventDetail.roundSummary.valuation.toFixed(2)),
        });
      const backgroundColor = randomColor({
        luminosity: "bright",
        alpha: 0.3,
        seed: eventDetail.roundSummary.name,
      });

      tempInvestmentData.push({
        name: eventDetail.roundSummary.name,
        type: eventDetail.roundSummary.type,
        color: backgroundColor,
        value: getValueOfEvent("Investment", eventDetail.roundSummary),
        date: eventDetail.roundSummary.date,
        valuation: eventDetail.roundSummary.valuation,
      });

      eventDetail.capTable.forEach((shareholder) => {
        const backgroundColor = randomColor({
          luminosity: "bright",
          alpha: 0.3,
          seed: shareholder.name,
        });
        if (!tempShareHoldersDetailName.includes(shareholder.name || "")) {
          tempShareHoldersDetailName.push(shareholder.name || "");
          const arrayZero = Array(indexDetails).fill(null);
          shareHolderInvestment.push({
            name: shareholder.name || "",
            type: shareholder.type || "",
            color: backgroundColor,
            value: 0,
            listValue: [...arrayZero, shareholder.fdbPercentage * 100],
            date: "",
            isVisible: false,
            isDisabled: false,
          });
        } else {
          shareHolderInvestment.forEach((shareholderInvestment) => {
            if (shareholderInvestment.name === shareholder.name) {
              shareholderInvestment.listValue = [
                ...shareholderInvestment.listValue,
                shareholder.fdbPercentage * 100,
              ];
            }
          });
        }
      });
      indexDetails += 1;
    }
  });
  const sortResult = sort(shareHolderInvestment, "value", true);
  const allShareholder = sortResult.map((shareholder, index) => {
    if (index > 5) return shareholder;
    else return { ...shareholder, isVisible: true };
  });
  return {
    allRoundDetails,
    nameOfAllRound,
    tempInvestmentData,
    allShareholder,
    markLabels,
  };
};

function arrayDifference(array1: any, array2: any): string[] {
  const set2 = new Set(array2);
  return array1.filter((element: any) => !set2.has(element));
}

const getValueOfEvent = (xAxis: string, roundSummary: RoundSummary) =>
  xAxis === "Valution"
    ? roundSummary.valuation
    : xAxis === "Investment"
    ? roundSummary.investment
    : roundSummary.pps;

export const getAllHeader = (
  dashboardCard: DashboardCardDetails
): HeaderDataModel[] => [
  {
    name: "Capital Raised",
    value: dashboardCard?.currentCapital || 0,
    preValue: dashboardCard.previousCapital || 0,
  },
  {
    name: "Valuation",
    value: dashboardCard.currentValutaion || 0,
    preValue: dashboardCard?.previousValuation || 0,
  },
  {
    name: "Price Per Share",
    value: dashboardCard?.currentSharePrice || 0,
    preValue: dashboardCard?.previousSharePrice || 0,
  },
  {
    name: "Stakeholders",
    value: dashboardCard?.currentShareholders || 0,
    preValue: dashboardCard?.previousShareHolders || 0,
  },
];
