import * as Yup from "yup";

export interface DirectorDataModel {
  id?: number;
  fullName: string;
  typeOfDirector: string;
  emailId: string;
  din: string;
  address?: string;
  contactNumber?: string;
  nationality: string;
  dateOfBirth?: string;
  countryOfResidence?: string;
  dateOfAppointement?: string;
  dateOfBoardResoluitonForAppointement?: string;
  isEdit?: boolean;
  shouldShowDialog?: boolean;
  isSelected?: boolean;
  isResigned?: boolean;
  resignationDate?: string;
  tags?: string[];
  investorNominees?: string[];
  transactions?: DirectorTransaction;
}

enum DirectorTransactionType {
  Appointment,
  Resignation,
}

export interface DirectorTransaction {
  id?: string;
  companyId?: string;
  directorId?: string;
  type?: DirectorTransactionType;
  date: string;
  boardResolutionDate?: string;
  director?: any;
}

export const directorInitData: DirectorDataModel = {
  fullName: "",
  typeOfDirector: "",
  emailId: "",
  din: "",
  address: "",
  contactNumber: "",
  nationality: "India",
  dateOfBirth: "",
  countryOfResidence: "",
  dateOfAppointement: "",
  dateOfBoardResoluitonForAppointement: "",
  isEdit: false,
  shouldShowDialog: false,
  isSelected: false,
  isResigned: false,
  resignationDate: "",
  tags: [],
  investorNominees: [],
  transactions: {} as DirectorTransaction,
};

export const directorDetailSchema = Yup.object().shape({
  fullName: Yup.string().required().label("Director Name"),
  typeOfDirector: Yup.string().required().label("Type Of Director"),
  din: Yup.string().required().label("DIN"),
  emailId: Yup.string().required().label("Email Id"),
  resignationDate: Yup.string().when(["isResigned"], {
    is: (isResigned: boolean) => isResigned === true,
    then: Yup.string().required().label("Resignation Date"),
    otherwise: Yup.string().notRequired(),
  }),
});
