import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  BoxContainer,
  Center,
  HStack,
  VStack,
} from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  CompanyDetailsPostModel,
  FounderDetailDataModel,
} from "../../types/OnBoardDataModel";
import { initialFounderData } from "./initValues";
import AddEditFounder from "../../modals/AddEditFounder";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Headings } from "../../components/Headings";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";

export default function FounderTable() {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const { values, setFieldValue } = formik;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof FounderDetailDataModel | "" | undefined;
    ascending: boolean;
  }>({ field: "fullName", ascending: true });
  const [dialog, setDialog] = useState<{
    message?: string;
    open: boolean;
    founder?: FounderDetailDataModel;
    mode?: "Edit" | "Add" | "Delete" | "Bulk Delete";
    index?: number;
  }>({
    open: false,
    mode: "Add",
  });

  const [tempFounderDetails, setTempFounderDetails] =
    useState<FounderDetailDataModel[]>();

  const [selectedItems, setSelectedItems] = useState<FounderDetailDataModel[]>(
    []
  );

  let founderList: FounderDetailDataModel[] = values.founders || [];

  founderList = useMemo(() => {
    if (!founderList) return [];
    const filterResult = globalFilter(founderList, globalFilterText, [
      "fullName",
      "emailId",
      "nationality",
    ]);
    const sortResult = sort(
      filterResult,
      sortField?.field,
      sortField?.ascending
    );
    return sortResult;
  }, [founderList, globalFilterText, sortField]);

  const onSelectionChange = (founder: FounderDetailDataModel) =>
    setSelectedItems((founders) =>
      founders.find((individualFounder) => individualFounder === founder)
        ? founders.filter((individualFounder) => individualFounder !== founder)
        : [...founders, founder]
    );

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(founderList);
    } else {
      setSelectedItems([]);
    }
  }, [founderList, selectAllChecked]);
  function handleSecondaryAction() {
    setFieldValue("founders", tempFounderDetails);
  }
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return founderList.slice(firstPageIndex, lastPageIndex);
  }, [founderList, currentPage, pageSize]);

  function handleAction(action: Action, founder?: FounderDetailDataModel) {
    setTempFounderDetails(formik.values.founders);
    const index = formik.values.founders.findIndex(
      (individualFounder) => individualFounder === founder
    );
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        founder,
        message: "",
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
        founder,
        message: "",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        founder,
        message: "Do you want to delete this Founder Details?",
      });
    } else if (action.name === "Bulk Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Bulk Delete",
        founder,
        message: "Do you want to delete selected Founder Details?",
      });
    }
  }

  const deleteAllFounder = () => {
    if (selectedItems.length === 0) return;
    const founders = selectedItems;
    setFieldValue(
      "founders",
      values?.founders?.filter((founder) => !founders?.includes(founder))
    );
    handleAction(
      {
        name: "Bulk Delete",
        disabled: false,
      },
      founders[0]
    );
    toast("Founder Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
    setSelectAllChecked(false);
  };

  function deleteParticularFounder(founder?: FounderDetailDataModel) {
    setFieldValue(
      "founders",
      values?.founders?.filter(
        (individualFounder) => individualFounder !== founder
      )
    );
    toast("Founder Deleted Successfully!", {
      type: "success",
      autoClose: 2000,
    });
    setDialog({ open: false });
  }

  function handleAdd(index: number) {
    const founders = [...values.founders];
    founders.splice(index, 0, initialFounderData);
    setFieldValue("founders", founders);
  }

  return (
    // <div className="w-full mt-10 bg-white border border-borderColor rounded-md shadow-box">
    //   <Box className="w-full sm:px-10 sm:py-4 p-4  bg-white rounded-lg min-w-min">
    <BoxContainer>
      <HStack
        aria-label="toolbar"
        className="justify-between mb-8 sm:flex-row flex-col"
      >
        <HStack className="justify-between">
          <Headings
            text="Founder Details"
            subText={`${values.founders?.length} Founders`}
          />
          <HStack>
            <Center className="p-2 px-3 mx-2   sm:hidden block rounded text-slate-dark bg-slate-light  border border-borderColor">
              <Tooltip text="Delete">
                <IconCTAButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    setDialog({ open: true });
                    handleAction(
                      {
                        name: "Bulk Delete",
                        disabled: false,
                      },
                      undefined
                    );
                  }}
                  iconName={"fluent:delete-24-regular"}
                  className={`py-2`}
                />
              </Tooltip>
            </Center>
            <PrimaryCTAButton
              event-name="Add Founder"
              className="min-w-max sm:hidden block"
              onClick={() => {
                handleAdd(values.founders?.length);
                handleAction({
                  name: "Add",
                  disabled: false,
                });
              }}
            >
              Add
            </PrimaryCTAButton>
          </HStack>
        </HStack>
        <HStack className="h-8 min-w-min sm:mt-0 gap-4 mt-2">
          <Tooltip text="Delete">
            <IconCTAButton
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setDialog({ open: true });
                handleAction(
                  {
                    name: "Bulk Delete",
                    disabled: false,
                  },
                  undefined
                );
              }}
              iconName={"fluent:delete-24-regular"}
              className={`py-2`}
            />
          </Tooltip>
          <HStack className="items-center p-2 rounded w-full bg-transparent text-slate-dark border border-inputBorderFocus">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="w-full font-medium border-none outline-none focus:ring-0 text-xs2 bg-inherit"
              placeholder="Search"
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>

          <PrimaryCTAButton
            className=" min-w-max sm:block hidden"
            event-name="Add Founder"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              handleAdd(values.founders?.length);
              handleAction({
                name: "Add",
                disabled: false,
              });
            }}
          >
            Add
          </PrimaryCTAButton>
        </HStack>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Delete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => deleteParticularFounder(dialog.founder)}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Edit" ? (
          <AddEditFounder
            founderIndex={dialog.index || 0}
            founder={dialog.founder}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => {
              handleSecondaryAction();
              setDialog({ open: false });
            }}
            mode="Edit"
          />
        ) : dialog.mode === "Add" ? (
          <AddEditFounder
            founderIndex={values.founders.length - 1}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => {
              handleSecondaryAction();
              setDialog({ open: false });
            }}
            mode="Add"
          />
        ) : (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => deleteAllFounder()}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
      </Dialog>
      <VStack className="w-full gap-8">
        {/* <Box className="max-h-full overflow-auto"> */}
        <table className="w-full table-space">
          <thead className="text-xs font-medium text-gray-light">
            <tr>
              <td className="py-3">
                <input
                  type="checkbox"
                  className="accent-orange-501 outline-hidden cursor-pointer"
                  checked={selectAllChecked}
                  onChange={(e) => {
                    setSelectAllChecked(!selectAllChecked);
                  }}
                ></input>
              </td>

              <>
                <td
                  className="py-3 hover:cursor-pointer"
                  onClick={() =>
                    setSortField({
                      field: "fullName",
                      ascending: !sortField?.ascending,
                    })
                  }
                >
                  Founder Name
                </td>
              </>

              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "emailId",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Email Id
              </td>
              <td
                className="py-3 hover:cursor-pointer"
                onClick={() =>
                  setSortField({
                    field: "nationality",
                    ascending: !sortField?.ascending,
                  })
                }
              >
                Nationality
              </td>
              <td className="py-3"></td>
            </tr>
          </thead>
          {values.founders && (
            <tbody>
              {currentTableData &&
                currentTableData?.map((founder, index) => (
                  <tr
                    key={founder.emailId + index}
                    className="border-t border-dashed "
                  >
                    <td className="py-5 align-top">
                      <Box>
                        <input
                          type="checkbox"
                          className="accent-orange-501 outline-hidden cursor-pointer  "
                          checked={selectedItems.indexOf(founder) !== -1}
                          onChange={(e) => onSelectionChange(founder)}
                        ></input>
                      </Box>
                    </td>

                    <>
                      <td className="py-4 align-top ">
                        <HStack className="w-32 ">
                          <Box>
                            <p
                              className={` text-xs font-medium text-gray-dark `}
                            >
                              {founder.fullName}
                            </p>
                          </Box>
                        </HStack>
                      </td>
                    </>

                    <td className="py-4 align-top">
                      <Box>
                        <HStack>
                          <p
                            className={` w-32 text-xs font-medium text-gray-dark`}
                          >
                            {founder.emailId}
                          </p>
                        </HStack>
                      </Box>
                    </td>
                    <td className="py-4 align-top">
                      <Box>
                        <p className={` text-xs font-normal text-gray-dark`}>
                          {founder.nationality}
                        </p>
                      </Box>
                    </td>
                    <td className="px-2 py-4 align-top">
                      <CTADropdown
                        dropdownClassName="bottom-auto"
                        actions={[
                          {
                            name: "Edit",
                            disabled: false,
                          },
                          {
                            name: "Delete",
                            disabled: false,
                          },
                        ]}
                        onAction={(action) => handleAction(action, founder)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {values.founders?.length <= 0 && (
          <div className="text-center text-black">
            <EmptyTableIllustration
              text={"No Founders Data Found. Please Add the founders"}
            />
          </div>
        )}
        {/* </Box> */}
        <Box className="flex justify-between mt-8 cursor-pointer">
          <BasicMenu
            defaultValue={pageSize}
            options={[5, 10, 20, 50, 100]}
            onOptionChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          />
          <Pagination
            currentPage={currentPage}
            totalCount={founderList?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </VStack>
      {/* </Box> */}
    </BoxContainer>
  );
}
