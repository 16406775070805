import { Icon } from "@iconify/react";
import { useEffect, useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useMatch, useNavigate } from "react-router";
import * as React from "react";
import { toast } from "react-toastify";
import { useAuthStore, useAuthorizationStore } from "../store";
import { useGetAllUserCompanies, useGetUserContext } from "../queries/auth";
import { useGraphLoaderStore } from "../store/graphLoaderStore";
import { useShareholderInstrumentStore } from "../store/shareholderInstrumentStore";
import { useInternalSwitchStateStore } from "../store/searchDropDown";
import { setUserProperTiesOnFirebase } from "../firebaseAnalytics";
import { useRMCreationStore } from "../store/roundCreationStore";
import { AssociatedCompany } from "../types/UserDetailModel";
import { queryClient } from "../queries";
import { HStack, VStack } from "../components/utils";
import Avatar from "../pages/Utility/Avatar";
import { Input } from "../components/shared/InputField";
import { useGetUserProfile } from "../queries/userProfile";
import { doLogout } from "../api/Esop";
import { globalFilter } from "../utils/arrays";
import { switchCompanyApi } from "../api/auth";
import { useRoundModelStore } from "../store/roundModelingStore";
import { useSelectedSecurityTypeStoreStore } from "../store/selectedSecurityTypeStore";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function FlyoutProfile() {
  const [show, setShown] = useState(false);
  const { setAuth, role, user, clear } = useAuthStore();
  const [showCompanies, setShowCompanies] = useState(false);
  const { data: userContext } = useGetUserContext();
  const [loading, setLoading] = useState(false);
  const graphStore = useGraphLoaderStore();
  const shareholderInstrument = useShareholderInstrumentStore();
  const transactionList = useInternalSwitchStateStore();

  const navigate = useNavigate();
  const { data: userProfileDetails } = useGetUserProfile();

  const [searchInput, setSearchInput] = useState("");

  const contextRef = React.useRef<HTMLDivElement>(null);
  const contextRef2 = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUserProperTiesOnFirebase(user?.fullName || "", user?.nameOfTheCompany);
  }, [user, userContext]);

  const securityType = useSelectedSecurityTypeStoreStore();
  const { roundCreation: data, reset: discardRoundCreation } =
    useRMCreationStore();
  const roundCreationStore = useRoundModelStore();
  const pathname = useLocation().pathname;
  const goBack = () => {
    if (pathname.includes("planView")) {
      navigate("/options/allPlans");
    } else if (pathname.includes("employeeDetails")) {
      navigate("/options/allEmployees");
    } else if (
      pathname.includes("rights/new") ||
      pathname.includes("rights/shareholder")
    ) {
      navigate("/rights/overview");
    } else if (pathname.includes("shareholders/individual")) {
      navigate("/shareholders/overview");
    } else if (pathname.includes("security/individual")) {
      navigate("/security/overview");
    } else if (pathname.includes("addNewSecurity")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("securityClass")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("termsheet")) {
      navigate("/termsheet/overview");
    } else if (pathname.includes("rights/overview")) {
      navigate("/transactions/allTransactions");
    } else if (pathname.includes("/modeling")) {
      navigate("/modeling/overview");
    } else if (
      pathname.includes("view-option-buy-back") ||
      pathname.includes("start-buy-back") ||
      pathname.includes("create-option-buyback") ||
      pathname.includes("edit-options-buyback")
    ) {
      navigate("/options/optionBuyback");
    } else if (pathname.includes("add-edit-pool")) {
      navigate("/options/esopOverview");
    } else if (pathname.includes("rounds")) {
      navigate(`/rounds/allTransactions`);
    } else if (pathname.includes("rights/article")) {
      navigate("/rights/shareholder");
    } else if (pathname.includes("transaction"))
      navigate(`/transactions/allTransactions`);
    else if (pathname.includes("financials")) navigate(`/financials/overview`);
  };

  async function switchCompany(company: AssociatedCompany) {
    if (company.nameOfTheCompany === user?.nameOfTheCompany) return;
    graphStore.setIsLoading(true);
    await queryClient.resetQueries("userDetails");
    setLoading(true);
    localStorage.setItem("companyId", company.id.toString());
    setAuth({
      companyId: company.uuid,
    });
    shareholderInstrument.reset();
    transactionList.reset();
    switchCompanyApi(company.uuid)
      .then(async (_) => {
        setShowCompanies(false);
        setShown(false);
        queryClient.invalidateQueries();
        queryClient.refetchQueries("userContext");
        toast("Company switched", {
          type: "success",
          autoClose: 2000,
        });
        setLoading(false);
        graphStore.setIsLoading(false);
        goBack();
        window.location.reload();
      })
      .catch(async (err) => {
        queryClient.refetchQueries("userContext");
        toast("Company switched", {
          type: "success",
          autoClose: 2000,
        });
        graphStore.setIsLoading(false);
        setLoading(false);
        window.location.reload();
      });
  }

  const routes = [
    {
      name: "Profile",
      onClick: () => {
        handleEventForTracking({ eventName: `Profile Page` });
        navigate("/user/profile");
        setShown(false);
        setShowCompanies(false);
      },
      icon: UserCircleIcon,
    },
    {
      name: "Switch Company",
      onClick: () => {
        handleEventForTracking({ eventName: `Switch Company` });
      },
      icon: ArrowsRightLeftIcon,
    },
    {
      name: "Log Out",
      onClick: () => {
        logout();
        handleEventForTracking({ eventName: `Log out` });
        setShown(false);
        setShowCompanies(false);
      },
      icon: ArrowLeftOnRectangleIcon,
    },
  ];
  const { clearAuthorization } = useAuthorizationStore();
  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("companyId");
    localStorage.removeItem("hissaUrl");
    queryClient.clear();
    clear();
    clearAuthorization();
    doLogout();
    window.location.href = `${
      process.env.REACT_APP_LEGACY_URL ||
      localStorage.getItem("hissaUrl") ||
      "https://dev.hissa.com"
    }`;
  }
  const { data: companyData } = useGetAllUserCompanies();

  const companies = React.useMemo(() => {
    if (!companyData) return [];
    const filterResult = globalFilter(companyData, searchInput, [
      "nameOfTheCompany",
      "dba",
    ]);
    return filterResult;
  }, [searchInput, companyData]);
  const profileMatch = useMatch(`/${"/user/profile".split("/")[1]}/*`);

  return (
    <div>
      {loading ? (
        <div className="items-center px-6 py-2">
          <p className="text-sm animate-pulse font-medium">Switching...</p>
        </div>
      ) : (
        <Popover className="relative">
          <Transition
            show={showCompanies}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              onMouseEnter={() => {
                setShowCompanies(true);
                setShown(true);
              }}
              onMouseLeave={() => {
                setShowCompanies(false);
                setShown(false);
              }}
              ref={contextRef2}
              className="absolute z-20 mt-48 mr-80 flex w-screen max-w-max  right-0 "
            >
              {showCompanies && (
                <div className="bg-white h-72 overflow-y-scroll border divide-y w-full divide-gray-100 rounded dropdown-menu ">
                  <HStack className="sticky top-0 border rounded-b-lg border-borderColor items-center rounded justify-start bg-slate-light   text-slate-dark">
                    <Icon icon="fe:search" width="24" />
                    <Input
                      type="text"
                      value={searchInput}
                      className="w-full font-medium border-none outline-none focus:ring-0 text-xs2 bg-inherit"
                      placeholder="Search Company"
                      onChange={(e: any) => setSearchInput(e.target.value)}
                    />
                  </HStack>
                  {companies.map((company, index) => (
                    <li
                      onMouseDown={(e) => {
                        e.preventDefault();
                        switchCompany(company);
                      }}
                      key={company.id}
                      className="block px-4 py-2 hover:bg-gray-100 w-full"
                    >
                      <p className="items-center justify-between w-64">
                        {company.dba !== ""
                          ? `${company.nameOfTheCompany} (${company.dba})`
                          : company.nameOfTheCompany}
                      </p>
                    </li>
                  ))}
                  {companies.length === 0 ? (
                    <ul className="bg-white rounded shadow ">
                      <li className="block px-4 py-2 hover:bg-gray-100">
                        No companies availables
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
          <Popover className="relative">
            <div>
              <Popover.Button
                onClick={() => setShown(!show)}
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowCompanies(false);
                }}
                className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 "
              >
                <span className="sr-only">Open user menu</span>
                <Avatar name={userProfileDetails?.fullName || ""} />
                <p className="w-24 text-sm text-gray-500 whitespace-nowrap text-ellipsis overflow-clip">
                  {companyData?.find((comp) => comp.uuid === user?.companyId)
                    ?.dba !== ""
                    ? `${user?.nameOfTheCompany} (${
                        companyData?.find(
                          (comp) => comp.uuid === user?.companyId
                        )?.dba
                      })`
                    : user?.nameOfTheCompany}
                </p>
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Popover.Button>
            </div>
            <Transition
              show={show}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowCompanies(false);
                }}
                ref={contextRef}
                className="absolute z-10 mt-5 flex w-screen max-w-max  right-0 "
              >
                <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
                  <div className=" divide-x divide-gray-900/5 bg-gray-50">
                    <div className="group relative flex gap-x-2 rounded-lg p-4 hover:bg-gray-50">
                      <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <Avatar name={userProfileDetails?.fullName || ""} />
                      </div>
                      <VStack className="w-full">
                        <HStack className="justify-between items-center w-full">
                          <a className="font-semibold text-gray-900 flex-1">
                            {userProfileDetails?.fullName}
                            <span className="absolute inset-0" />
                          </a>

                          <a className="ml-1 text-sm text-gray-500 text-ellipsis overflow-clip">
                            {role}
                          </a>
                        </HStack>

                        <p className="text-gray-600 whitespace-nowrap">
                          {userProfileDetails?.emailAddress}
                        </p>
                        <p className="text-sm text-gray-500 text-ellipsis overflow-clip">
                          {companyData?.find(
                            (comp) => comp.uuid === user?.companyId
                          )?.dba !== ""
                            ? `${user?.nameOfTheCompany} (${
                                companyData?.find(
                                  (comp) => comp.uuid === user?.companyId
                                )?.dba
                              })`
                            : user?.nameOfTheCompany}
                        </p>
                      </VStack>
                    </div>
                  </div>

                  <div className="p-2  cursor-pointer">
                    {routes.map((item) => (
                      <div
                        key={item.name}
                        onClick={() =>
                          item.name === "Switch Company"
                            ? setShowCompanies(!showCompanies)
                            : item.onClick()
                        }
                        className="group relative flex gap-x-4 rounded-lg p-2 hover:bg-gray-50 items-center"
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className={`h-6 w-6 text-gray-600 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <div>
                          <a
                            className={`font-semibold text-gray-900 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover>
      )}
    </div>
  );
}
