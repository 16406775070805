import { getNames } from "country-list";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import Select from "react-select";
import {
  DirectorDataModel,
  directorDetailSchema,
  directorInitData,
  DirectorTransaction,
} from "../types/DirectorDataModels";
import { PopupHeader } from "../components/Headings";
import { HStack, VStack, Error } from "../components/utils";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { Select as CustomSelect } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { typeOfDirectorDropDownValues } from "../constants/DropDownValues";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { queryClient } from "../queries";
import { DirectorDetailModel } from "../types/OnBoardDataModel";
import { useAddDirectorDetails } from "../queries/directorDetails";
import { useInternalSwitchStateStore } from "../store/searchDropDown";
import { SelectOptionType } from "../types/BoardMeetingsModels";

export const AddEditDirector = ({
  director,
  //   onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: any) => {
  const { mutate: addDirectorDetailsMutation } = useAddDirectorDetails();
  const transactionSwitchStore = useInternalSwitchStateStore();

  const shareholderList: SelectOptionType[] =
    transactionSwitchStore.shareholders?.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));

  const [loading, setLoading] = useState(false);

  const formik = useFormik<DirectorDataModel>({
    initialValues: (director as DirectorDataModel) || directorInitData,
    validationSchema: directorDetailSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => {
      setLoading(true);
      const latestTransaction: DirectorTransaction = {
        date:
          (data.isResigned ? data.resignationDate : data.dateOfAppointement) ||
          "",
      };
      const directorDetails: DirectorDetailModel = {
        ...data,
        transactions: [latestTransaction],
      };

      addDirectorDetailsMutation(directorDetails, {
        onSuccess: () => {
          queryClient.invalidateQueries("getDirectorDetails");
          toast("Director Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          handleClose();
          setLoading(false);
        },
        onError: (err: any) => {
          toast(err, {
            type: "error",
            autoClose: 2000,
          });
          setLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    if (
      director &&
      director.transactions &&
      director.transactions.length > 0 &&
      director.transactions[0].type === "Resignation"
    ) {
      formik.setFieldValue(
        "resignationDate",
        director.transactions && director.transactions[0].date
      );
    }
  }, [director]);

  const handleClose = () => {
    onSecondaryAction();
  };

  const [value, setValue] = useState<SelectOptionType[]>(
    formik.values.investorNominees as SelectOptionType[]
  );

  return (
    <>
      <div className="transition ease-in duration-2000 opacity-100 enter:opacity-0">
        <PopupHeader
          text={
            mode !== "Edit" ? "Add Director Details" : "Edit Director Details"
          }
          onClick={() => {
            handleClose();
          }}
        />
        <VStack className="w-full px-10 py-7 gap-9">
          {/* <form onSubmit={formik.handleSubmit}> */}
          <VStack className="w-full gap-9">
            <HStack className="gap-8 ">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Director Name<span className="text-rose-400">*</span>
                </Label>
                <Input
                  type="text"
                  placeholder="Enter the director name"
                  className="w-96"
                  {...formik.getFieldProps("fullName")}
                />
                {formik.errors?.fullName && (
                  <Error text={formik.errors?.fullName} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Type of Director<span className="text-rose-400">*</span>
                </Label>
                <CustomSelect
                  options={typeOfDirectorDropDownValues}
                  {...formik.getFieldProps("typeOfDirector")}
                />
                {formik.errors?.typeOfDirector && (
                  <Error text={formik.errors?.typeOfDirector} />
                )}
              </div>
            </HStack>
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Email Id
                  <span className="text-rose-400">*</span>
                </Label>
                <Input
                  type="email"
                  placeholder="Eg: example@domain.com"
                  className="w-96"
                  {...formik.getFieldProps(`emailId`)}
                />
                {formik.errors?.emailId && (
                  <Error text={formik.errors?.emailId} />
                )}
              </div>

              <div className="flex-1">
                <Label className="text-sm font-normal">
                  DIN Number
                  <span className="text-rose-400">*</span>
                </Label>
                <Input
                  type="text"
                  placeholder="Enter DIN Number"
                  className="w-96"
                  {...formik.getFieldProps(`din`)}
                />
                {formik.errors?.din && <Error text={formik.errors?.din} />}
              </div>
            </HStack>
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">Address</Label>
                <TextArea {...formik.getFieldProps(`address`)} />
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Contact Number</Label>
                <Input
                  type="text"
                  placeholder="Eg:9093409093"
                  className="w-96"
                  {...formik.getFieldProps(`contactNumber`)}
                />
              </div>
            </HStack>
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Nationality<span className="text-rose-400">*</span>
                </Label>
                <CustomSelect
                  className="w-96"
                  options={getNames().sort()}
                  {...formik.getFieldProps(`nationality`)}
                />
                {formik.errors?.nationality && (
                  <Error text={formik.errors?.nationality} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Date of Birth</Label>
                <Input
                  type="date"
                  placeholder="Eg:DD/MM/YYYY"
                  className="w-96"
                  {...formik.getFieldProps(`dateOfBirth`)}
                />
                {formik.errors?.dateOfBirth && (
                  <Error text={formik.errors?.dateOfBirth} />
                )}
              </div>
            </HStack>
            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Date of Board Resolution for Appointment
                </Label>
                <Input
                  type="date"
                  placeholder="Eg:DD/MM/YYYY"
                  className="w-96"
                  {...formik.getFieldProps(
                    `dateOfBoardResoluitonForAppointement`
                  )}
                />
                {formik.errors?.dateOfBoardResoluitonForAppointement && (
                  <Error
                    text={formik.errors?.dateOfBoardResoluitonForAppointement}
                  />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Date of Appointment
                </Label>
                <Input
                  type="date"
                  placeholder="Eg:DD/MM/YYYY"
                  className="w-96"
                  {...formik.getFieldProps(`dateOfAppointement`)}
                />
                {formik.errors?.dateOfAppointement && (
                  <Error text={formik.errors?.dateOfAppointement} />
                )}
              </div>
            </HStack>

            <HStack className="gap-8">
              <div className="flex-1">
                <HStack className="mt-6 gap-4 items-center">
                  <Label className="text-sm font-normal">Is Resigned?</Label>
                  <SwitchButton
                    label=""
                    value={formik.values.isResigned || false}
                    onClick={() => {
                      // setIsResigned(!isResigned);
                      formik.setFieldValue(
                        `isResigned`,
                        !formik.values.isResigned
                      );
                    }}
                    //   {...formik.getFieldProps(
                    //     `isResigned`
                    //   )}
                    className="mt-4"
                  />
                </HStack>
              </div>
              {formik.values.isResigned && (
                <div className="flex-1">
                  <Label className="text-sm font-normal required">
                    Resignation Date <span className="text-rose-400">*</span>
                  </Label>
                  <Input
                    type="date"
                    className="w-96"
                    max={new Date().toISOString().split("T")[0]}
                    {...formik.getFieldProps(`resignationDate`)}
                    // onChange={(e) => {
                    //   if (e.target.value !== '') {
                    //     setDateError(undefined);
                    //   }
                    //   formik.setFieldValue(`resignationDate`, e.target.value);
                    // }}
                  />
                  {formik.errors?.resignationDate && (
                    <Error text={formik.errors?.resignationDate} />
                  )}
                </div>
              )}
            </HStack>

            <HStack className="gap-8">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Tags
                  <span className="text-xxs text-gray-400">{` (Comma separated)`}</span>
                </Label>
                <Input
                  type="text"
                  placeholder="Eg: Tag1, Tag2, Tag3"
                  value={formik.values.tags?.join(", ")}
                  className="w-96"
                  onChange={(e: any) => {
                    const tags: string[] = e.target.value
                      .split(",")
                      .map((value: string) => value.trim());
                    formik.setFieldValue(`tags`, tags);
                  }}
                />
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Investor Nominee</Label>
                <Select
                  className="form-control-solid mt-2"
                  options={shareholderList}
                  placeholder={"Select ..."}
                  isMulti={true}
                  value={value}
                  classNamePrefix="select2-selection"
                  onChange={(e: any) => {
                    setValue(e);
                    formik.setFieldValue(`investorNominees`, e);
                  }}
                />
              </div>
            </HStack>

            <HStack className="justify-between h-8">
              <SecondaryCTAButton
                event-name="Cancel Director Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Save Director Modal"
                type="submit"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  formik.handleSubmit();
                }}
                loading={loading}
                disabled={loading}
              >
                <Icon
                  icon="lsicon:save-filled"
                  width={18}
                  height={18}
                  className={`mr-1 border-transparent text-white bg-transparent rounded-md`}
                />
                Save
              </PrimaryCTAButton>
            </HStack>
          </VStack>
          {/* </form> */}
        </VStack>
      </div>
    </>
  );
};
