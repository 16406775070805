import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import DocumentsAgGridTable, { DocTypes } from "./DocumentsAgGridTable";
import { useGetDirectorDocuments } from "../../queries/directorDetails";
import { useGetMeetingDocuments } from "../../queries/boardMeetingDetails";

export function DocumentsPopup({
  id,
  onSecondaryAction = () => {},
  onPrimaryAction = () => {},
  type,
}: {
  id: string;
  onSecondaryAction: () => void;
  onPrimaryAction: () => void;
  type: "director" | "meeting";
}) {
  const companyId = useAuthStore().companyId ?? "";

  const { data: docData } =
    type === "director"
      ? useGetDirectorDocuments(id || "", companyId)
      : useGetMeetingDocuments(id || "", companyId);

  return (
    <div>
      <Box className="px-10 text-lg font-medium border-b py-7 w-200">
        <h6 className="flex justify-between">
          {"Documents"}
          <XMarkIcon
            className="h-5 mt-1 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="p-6 gap-9 w-200">
        <DocumentsAgGridTable
          transactionDocuments={(docData as unknown as DocTypes[]) || []}
          docId={id}
          type={type}
        />
      </VStack>
    </div>
  );
}
