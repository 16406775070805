import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { Action } from "../../components/shared/Dropdown";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { HStack, VStack } from "../../components/utils";
import "../../styles/reset.css";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import {
  CurrentTabModel,
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import AlertDialog from "../../components/shared/AlertDialog";
import DashboardTooltip from "../../components/shared/DashBoardToolTip";
import { useTransactionStore } from "../../store/transactionStore";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import { classNames, limitString } from "../../utils/string";
import AddTransactionModal from "../quickRound/AddTransactionPrimary";
import AddSecondaryTransactionModal from "../quickRound/AddTransactionSecondary";
import AGGridPrimaryRoundTransactionTable from "./PrimaryRoundTransactionAgTable";
import AGGridSecondaryRoundTransactionTable from "./SecondaryRoundTransactionAgTable";
import AgGridOutstandingConvertibleTable from "../newQuickTransaction/OutstandingConvertibleAgGridTable";
import { OutstandingConvertible } from "../../types/Modeling";
import TransactionDocumentsAgGridTable, {
  TransactionDocTypes,
} from "../newQuickTransaction/TransactionDocumentsAgGridTable";
import { useAuthStore } from "../../store";
import { useGetTransactionDocuments } from "../../queries/transactionRound";
import { TransactionNotesPopup } from "../newQuickTransaction/TransactionNotesPopup";

interface ShareholderTypeTotal {
  name: string;
  value: number;
}

function AGGridRoundTransactionTable({
  isInvestorPortal = false,
  isPreviewOnly = false,
  primaryTransactionProps,
  secondaryTransactionProps,
  isModeling = false,
  convertibles,
}: {
  isPreviewOnly: boolean;
  isInvestorPortal?: boolean;
  primaryTransactionProps: PrimaryTransactionDataModel[];
  secondaryTransactionProps: SecondaryTransactionDataModel[];
  isModeling?: boolean;
  convertibles?: OutstandingConvertible[];
}) {
  const currency = getCurrencyType();
  const transactionStore = useTransactionStore();
  const companyId = useAuthStore.getState().companyId || "";
  const eventId = window.location.pathname?.split("/").at(-1);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: PrimaryTransactionDataModel | SecondaryTransactionDataModel;
    index?: number;
    mode?:
      | "Edit"
      | "Delete"
      | "Add"
      | "AddSecondary"
      | "EditSecondary"
      | "SecondaryDelete"
      | "Notes";
  }>({
    open: false,
  });

  const tabs: CurrentTabModel[] = [
    { name: "Primary" },
    { name: "Secondary" },
    { name: "Convertible" },
    { name: "Documents" },
    { name: "Notes" },
  ];
  const [currentTab, setCurrentTab] = useState("Primary");

  const { data: transactionData } = useGetTransactionDocuments(
    eventId || "",
    companyId
  );

  useEffect(() => {
    if (primaryTransactionProps.length > 0) setCurrentTab("Primary");
    else setCurrentTab("Secondary");
  }, [primaryTransactionProps]);
  const [totalValue, setTotalValue] = useState<ShareholderTypeTotal[]>([]);

  return (
    <div
      className={`bg-white border border-borderColor ${
        isModeling ? "" : "shadow-box"
      } mb-8 rounded-lg`}
    >
      <div className="flex-col justify-between px-4 pt-8 sm:flex lg:flex-row sm:px-6 lg:px-8">
        {tabs.length > 0 && (
          <HStack className="items-center justify-between lg:justify-start">
            <p className="mr-4 text-lg font-medium md:mr-12">Transactions</p>
            <HStack>
              {primaryTransactionProps.length > 0 && (
                <div
                  className={classNames(
                    "inline-flex rounded-md border items-center px-3 py-2  shadow-box text-xs font-semibold leading-4 mr-2 hover:cursor-pointer",
                    currentTab === "Primary"
                      ? " border-transparent bg-orange-p text-white hover:bg-orange-500"
                      : " bg-white  focus:opacity-90 border-red-500   text-red-500  hover:bg-gray-50"
                  )}
                  aria-current={currentTab === "Primary" ? "page" : undefined}
                  onClick={() => {
                    setCurrentTab("Primary");
                  }}
                >
                  <span>{"Primary"}</span>
                  <span aria-hidden="true" />
                </div>
              )}
              {secondaryTransactionProps.length > 0 && (
                <div
                  className={classNames(
                    "inline-flex rounded-md border items-center px-3 py-2  shadow-box text-xs font-semibold leading-4 mr-2 hover:cursor-pointer",
                    currentTab === "Secondary"
                      ? " border-transparent bg-orange-p text-white hover:bg-orange-500"
                      : " bg-white  focus:opacity-90 border-red-500   text-red-500  hover:bg-gray-50"
                  )}
                  aria-current={currentTab === "Secondary" ? "page" : undefined}
                  onClick={() => {
                    setCurrentTab("Secondary");
                  }}
                >
                  <span>{"Secondary"}</span>
                  <span aria-hidden="true" />
                </div>
              )}
              <div
                className={classNames(
                  "inline-flex rounded-md border items-center px-3 py-2  shadow-box text-xs font-semibold leading-4 mr-2 hover:cursor-pointer",
                  currentTab === "Convertibles"
                    ? " border-transparent bg-orange-p text-white hover:bg-orange-500"
                    : " bg-white  focus:opacity-90 border-red-500   text-red-500  hover:bg-gray-50"
                )}
                aria-current={
                  currentTab === "Convertibles" ? "page" : undefined
                }
                onClick={() => {
                  setCurrentTab("Convertibles");
                }}
              >
                <span>{"Convertibles"}</span>
                <span aria-hidden="true" />
              </div>
              <div
                className={classNames(
                  "inline-flex rounded-md border items-center px-3 py-2  shadow-box text-xs font-semibold leading-4 mr-2 hover:cursor-pointer justify-end",
                  currentTab === "Documents"
                    ? " border-transparent bg-orange-p text-white hover:bg-orange-500"
                    : " bg-white  focus:opacity-90 border-red-500 text-red-500  hover:bg-gray-50"
                )}
                aria-current={currentTab === "Documents" ? "page" : undefined}
                onClick={() => {
                  setCurrentTab("Documents");
                }}
              >
                <Icon
                  icon="ion:documents-sharp"
                  width={18}
                  height={18}
                  className={`mr-1 ${
                    currentTab === "Documents"
                      ? " border-transparent text-white bg-transparent"
                      : " bg-white  focus:opacity-90 border-red-500 text-red-500  hover:bg-gray-50"
                  }`}
                />
                <span className="icon-[mdi-light--folder-multiple]"></span>
                <span>{"Documents"}</span>
                <span aria-hidden="true" />
              </div>
              <div
                className={classNames(
                  "inline-flex rounded-md border items-center px-3 py-2  shadow-box text-xs font-semibold leading-4 mr-2 hover:cursor-pointer justify-end",
                  currentTab === "Notes"
                    ? " border-transparent bg-orange-p text-white hover:bg-orange-500"
                    : " bg-white  focus:opacity-90 border-red-500 text-red-500  hover:bg-gray-50"
                )}
                aria-current={currentTab === "Notes" ? "page" : undefined}
                onClick={() => {
                  setDialog({
                    open: true,
                    mode: "Notes",
                  });
                  setCurrentTab("Notes");
                }}
              >
                <Icon
                  icon="ion:newspaper"
                  width={18}
                  height={18}
                  className={`mr-1 ${
                    currentTab === "Notes"
                      ? " border-transparent text-white bg-transparent"
                      : " bg-white  focus:opacity-90 border-red-500 text-red-500  hover:bg-gray-50"
                  }`}
                />
                <span className="icon-[mdi-light--folder-multiple]"></span>
                <span>{"Notes"}</span>
                <span aria-hidden="true" />
              </div>
            </HStack>
          </HStack>
        )}
        <HStack className="justify-end mt-4 lg:mt-0 sm:ml-16">
          {!isPreviewOnly && (
            <PrimaryCTAButton
              event-name={`Add ${currentTab} Transaction`}
              onClick={() =>
                currentTab === "Primary"
                  ? setDialog({
                      open: true,
                      mode: "Add",
                    })
                  : setDialog({
                      open: true,
                      mode: "AddSecondary",
                    })
              }
            >
              Add
            </PrimaryCTAButton>
          )}
        </HStack>
      </div>
      <HStack className="items-center w-full px-4 mt-4 mb-2 sm:px-6 lg:px-8">
        {totalValue.map((event, index) => {
          const sum = _.sumBy(totalValue.map((event) => event.value));
          const transparentValue =
            (30 + (80 / totalValue.length) * (totalValue.length - index)) / 100;
          const color = `rgba(0, 44, 164, ${transparentValue} )`;
          const total = `${(event.value / sum) * 100}%`;
          return (
            <VStack
              key={event.name + event.value}
              className={` max-w-full h-[25px] min-w-[50px] px-2 border rounded justify-start`}
              style={{
                backgroundColor: color,
                width: total,
              }}
            >
              <DashboardTooltip
                text={`${event.name} : ${event.value.toLocaleString(currency)}`}
              >
                <HStack className="justify-start gap-1 overflow-hidden text-center text-white whitespace-nowrap">
                  {event.value > 0 ? <p>{limitString(event.name, 24)}</p> : ""}{" "}
                  {event.value > 0 ? " : " : ""}
                  {event.value > 0 ? (
                    <FormatNumberSpan value={event.value} format={currency} />
                  ) : (
                    ""
                  )}
                </HStack>
              </DashboardTooltip>
            </VStack>
          );
        })}
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Delete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => {
              if (dialog?.data) {
                transactionStore.removeTransaction(
                  dialog?.data as PrimaryTransactionDataModel
                );
              }
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Add" ? (
          <AddTransactionModal
            mode="Add"
            onPrimaryAction={(primary: PrimaryTransactionDataModel) => {
              transactionStore.setTransaction(primary);
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : dialog.mode === "AddSecondary" ? (
          <AddSecondaryTransactionModal
            mode="Add"
            onPrimaryAction={(secondary: SecondaryTransactionDataModel) => {
              transactionStore.setSecondary(secondary);
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : dialog.mode === "Edit" ? (
          <AddTransactionModal
            mode="Edit"
            onPrimaryAction={(primary: PrimaryTransactionDataModel) => {
              transactionStore.setTransaction(primary);
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            initialValue={dialog.data as PrimaryTransactionDataModel} //  values
          />
        ) : dialog.mode === "EditSecondary" ? (
          <AddSecondaryTransactionModal
            mode="Edit"
            onPrimaryAction={(secondary: SecondaryTransactionDataModel) => {
              transactionStore.setSecondary(secondary);
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
            initialValue={dialog.data as SecondaryTransactionDataModel} //  val
          />
        ) : dialog.mode === "SecondaryDelete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => {
              if (dialog?.data) {
                transactionStore.removeSecondary(
                  dialog?.data as SecondaryTransactionDataModel
                );
              }
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : (
          <div></div>
        )}
      </Dialog>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle ">
            <div className="mb-4 overflow-hidden md:rounded-lg">
              {currentTab === "Primary" ? (
                <div>
                  {(secondaryTransactionProps.length > 0 ||
                    primaryTransactionProps.length > 0) && (
                    <AGGridPrimaryRoundTransactionTable
                      isInvestorPortal={isInvestorPortal}
                      isPreviewOnly={true}
                      secondaryTransactionProps={secondaryTransactionProps}
                      primaryTransactionProps={primaryTransactionProps}
                    />
                  )}
                </div>
              ) : currentTab === "Convertibles" ? (
                <div>
                  <AgGridOutstandingConvertibleTable
                    outstandingConvertibles={convertibles || []}
                  />
                </div>
              ) : currentTab === "Secondary" ? (
                <div>
                  {(secondaryTransactionProps.length > 0 ||
                    primaryTransactionProps.length > 0) && (
                    <AGGridSecondaryRoundTransactionTable
                      isInvestorPortal={isInvestorPortal}
                      isPreviewOnly={true}
                      secondaryTransactionProps={secondaryTransactionProps}
                      primaryTransactionProps={primaryTransactionProps}
                    />
                  )}
                </div>
              ) : currentTab === "Documents" ? (
                <div>
                  <TransactionDocumentsAgGridTable
                    transactionDocuments={transactionData || []}
                    eventId={eventId || ""}
                  />
                </div>
              ) : (
                <div>
                  <Dialog open={dialog.open} maxWidth="md">
                    {dialog.mode === "Notes" ? (
                      <TransactionNotesPopup
                        eventId={eventId}
                        onPrimaryAction={() => {
                          setDialog({
                            open: false,
                          });
                          if (primaryTransactionProps.length > 0)
                            setCurrentTab("Primary");
                          else setCurrentTab("Secondary");
                        }}
                        onSecondaryAction={() => {
                          setDialog({
                            open: false,
                          });
                          if (primaryTransactionProps.length > 0)
                            setCurrentTab("Primary");
                          else setCurrentTab("Secondary");
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AGGridRoundTransactionTable;
