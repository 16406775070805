import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { LinkIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { HStack, VStack } from "../../components/utils";
import { tableEntryStyle } from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";
import { downloadS3File } from "../../utils/DownloadFile";
import { useDeleteTransactionDocument } from "../../queries/transactionRound";
import { queryClient } from "../../queries";
import { useGetUserRoleGrants } from "../../queries/auth";
import { useDeleteMeetingDocument } from "../../queries/boardMeetingDetails";
import { useDeleteDirectorDocument } from "../../queries/directorDetails";

export function DateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      {data.value ? (
        <p className={tableEntryStyle}>{formatDisplayDate(data.value)}</p>
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}

function getFileType(filename: string) {
  const extension = filename?.split(".")?.pop()?.toLowerCase();
  switch (extension) {
    case "xlsx":
    case "xlsm":
    case "xls":
      return "Excel";
    case "doc":
    case "docx":
      return "Doc";
    case "pdf":
      return "PDF";
    default:
      return "Unknown file type";
  }
}

export function formatBytes(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = sizes[i];
  const value = (bytes / 1024 ** i).toFixed(2);
  return `${value} ${size}`;
}

function downloadFile(fileUrl: string) {
  downloadS3File(fileUrl);
}

export const DocIconRender = (data: any) => (
  <HStack className="items-center w-fit rounded-md pb-4">
    {getFileType(data.data.docName) === "PDF" ? (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
        alt="pdf_icon"
        width={24}
        height={24}
        className="ml-2"
      />
    ) : getFileType(data.data.docName) === "Excel" ? (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg"
        alt="excel_icon"
        width={30}
        height={30}
      />
    ) : getFileType(data.data.docName) === "Doc" ? (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/f/fd/Microsoft_Office_Word_%282019%E2%80%93present%29.svg"
        alt="Word Document Icon"
        width={30}
        height={30}
      />
    ) : data.data.documentType !== "document" ? (
      <div className="text-blue-600 font-bold pt-1">
        <LinkIcon className="w-6 h-6" />
      </div>
    ) : (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Icon-txt.svg"
        alt="Text File Icon"
        width={30}
        height={30}
      />
    )}
  </HStack>
);
export const DownloadRender = (data: any) => (
  <p className="text-blue-600 text-sm font-medium underline pt-2.5 cursor-pointer">
    download
  </p>
);

export const DownloadIconComponent = (data: any) => {
  const { cId, token } = useParams() as {
    cId: string;
    token: string;
  };
  const [loading, setLoading] = useState(false);
  const { mutate: removeDirectorDocument } = useDeleteDirectorDocument();
  const { mutate: removeMeetingDocument } = useDeleteMeetingDocument();
  const { data: userRoleData } = useGetUserRoleGrants();
  function removeDirectorDoc(data: any) {
    removeDirectorDocument(
      {
        companyId: data.companyId,
        directorId: data.docId,
        documentId: data.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getDirectorDocuments");
          toast("Document Removed Successfully ", {
            type: "success",
            autoClose: 2000,
          });
          setLoading(false);
        },
        onError: (e: any) => {
          toast(
            `${e?.response?.data?.errorMessage || "Something went wrong"}`,
            { type: "error", autoClose: 2000 }
          );
          setLoading(false);
        },
      }
    );
  }
  function removeMeetingDoc(data: any) {
    removeMeetingDocument(
      { companyId: data.companyId, meetingId: data.docId, documentId: data.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getMeetingDocuments");
          toast("Document Removed Successfully ", {
            type: "success",
            autoClose: 2000,
          });
          setLoading(false);
        },
        onError: (e: any) => {
          toast(
            `${e?.response?.data?.errorMessage || "Something went wrong"}`,
            { type: "error", autoClose: 2000 }
          );
          setLoading(false);
        },
      }
    );
  }

  return (
    <HStack className="gap-3">
      {data.data.link && data.data.documentType === "document" ? (
        <div className="text-gray-400 hover:text-blue-600 font-bold pt-2 cursor-pointer">
          <ArrowDownTrayIcon
            className="w-6 h-6"
            onClick={() => {
              downloadFile(data.data.link);
            }}
          />
        </div>
      ) : (
        <div
          className={`${
            data.data.documentType !== "document"
              ? "text-gray-300 cursor-not-allowed"
              : "text-gray-400 hover:text-blue-600 cursor-pointer"
          } font-bold pt-2`}
        >
          <ArrowDownTrayIcon className="w-6 h-6" />
        </div>
      )}
      <div className="text-gray-400 hover:text-blue-600 font-bold pt-2 cursor-pointer">
        {!loading ? (
          userRoleData[0].role === "Admin Viewer" || cId ? (
            <div className={`text-gray-300 cursor-not-allowed font-bold `}>
              <TrashIcon className="w-6 h-6" />
            </div>
          ) : (
            <TrashIcon
              className="w-6 h-6"
              onClick={() => {
                setLoading(true);
                if (data.data.type === "director") removeDirectorDoc(data.data);
                if (data.data.type === "meeting") removeMeetingDoc(data.data);
              }}
            />
          )
        ) : (
          <Icon className="animate-spin " icon="lucide:loader-2" width={24} />
        )}
      </div>
    </HStack>
  );
};

export const DocumentNameRender = (data: any) => (
  <VStack>
    <p
      className={`text-labelColor md:text-sm text-xs pt-2 font-medium ${
        data.data.documentType !== "document"
          ? "cursor-pointer hover:text-primary"
          : ""
      } `}
      onClick={() => {
        if (data.data.documentType !== "document") {
          let url = data.data.link;
          if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = `https://${url}`;
          }
          window.open(url, "_blank");
        }
      }}
    >
      {data.data.docName}
    </p>
    {/* {data.data.size ? (
    <p className="text-descriptionColor md:text-xs text-xxs font-medium">
      {formatBytes(data.data.size)}
    </p>
    ) : <>-</>} */}
  </VStack>
);

export function TagsRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p>{data.data.tags}</p>
    </div>
  );
}
export function SizeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p>{data.data.size > 0 ? formatBytes(data.data.size) : "-"}</p>
    </div>
  );
}
