import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import {
  addBoardCompositionDetails,
  addBoardMeetingDetails,
  getBoardCompositionDetails,
  getBoardMeetingDetails,
  getInvBoardMeetingDetails,
  getMeetingDocuments,
  removeMeetingDetails,
  removeMeetingDocument,
  uploadMeetingDocuments,
} from "../api/boardMeetings";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetAllBoardMeetingDetails() {
  return useQuery({
    queryKey: "getBoardMeetingDetails",
    queryFn: getBoardMeetingDetails,
  });
}

export function useInvGetAllBoardMeetingDetails(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvBoardMeetingDetails", cId, token],
    queryFn: getInvBoardMeetingDetails,
  });
}

export function useAddBoardMeetingDetails() {
  return useMutation({
    mutationKey: "addBoardMeetingDetails",
    mutationFn: addBoardMeetingDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addMeetingDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addMeetingDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteBoardMeetingDetails() {
  return useMutation({
    mutationKey: "removeMeetingDetails",
    mutationFn: removeMeetingDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "removeMeetingDetails",
        success: true,
        eventType: "API",
      });
      toast("Meeting Details Removed Successfully!", {
        type: "success",
        autoClose: 2000,
      });
    },
    onError: (error: any) => {
      handleEventForTracking({
        eventName: "removeMeetingDetails",
        success: false,
        eventType: "API",
      });
      toast(error, {
        type: "error",
        autoClose: 2000,
      });
    },
  });
}

export function useGetBoardCompositionDetails() {
  return useQuery({
    queryKey: "getBoardCompositionDetails",
    queryFn: getBoardCompositionDetails,
  });
}

export function useAddBoardCompositionDetails() {
  return useMutation({
    mutationKey: "addBoardCompositionDetails",
    mutationFn: addBoardCompositionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addCompositionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addCompositionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetMeetingDocuments(directorId: string, companyId: string) {
  let enabled = false;
  if (directorId && directorId !== "") {
    enabled = true;
  }
  return useQuery({
    queryKey: ["getMeetingDocuments", directorId, companyId],
    queryFn: getMeetingDocuments,
    enabled,
  });
}

export function useAddMeetingDocuments() {
  return useMutation({
    mutationKey: "addMeetingDocuments",
    mutationFn: uploadMeetingDocuments,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addMeetingDocuments",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addMeetingDocuments",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteMeetingDocument() {
  return useMutation({
    mutationKey: "deleteMeetingDocuments",
    mutationFn: removeMeetingDocument,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "deleteMeetingDocuments",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteMeetingDocuments",
        success: false,
        eventType: "API",
      });
    },
  });
}
