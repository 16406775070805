import { useRef } from "react";
import { useNavigate } from "react-router";
import { HStack, VStack } from "../../components/utils";
import { tableEntryStyle } from "../../components/TableComponent";
import { limitString } from "../../utils/string";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import Avatar from "../Utility/Avatar";
import CaptableHoverCard from "./CaptableHoverCard";
import { CaptableTag } from "../../components/AllotmentTag";
import RoundTag from "../../shared/RoundTag";
import { checkVoid } from "../../utils/transactionUtils";

export function shareholderNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left ">
      <div className="flex">
        <div className="pt-3 pr-2 text-left">
          {data.node.level !== 0 && data.value !== "Total" ? (
            <Avatar name={data.value || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value === "Total" && data.node.level === 0
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name !== "Total" ? (
            <CaptableHoverCard shareholder={data.data} />
          ) : (
            ""
          )}
          <div>
            <p className="text-left font-semibold pt-3 text-xs2 text-[#464E5F]">
              {limitString(
                data.value?.toLowerCase() === "esop pool"
                  ? "ESOP Pool"
                  : data.value || "",
                24
              )}
            </p>
            {data.node.level !== 0 && data.value !== "Total" ? (
              <CaptableTag
                tagName={data.data.shareholderName.type ?? "Others"}
                dataEventName={"captableShareholderTypeDrilling"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </td>
  );
}

export function EquitySharesRender(data: any) {
  return (
    <VStack>
      <div>
        <div className="pt-3 whitespace-nowrap">
          <p className={tableEntryStyle}>
            {formatToReadableNumber({
              value: Number(data.data?.equityShares),
              format: data.data.currency,
            })}
          </p>
        </div>
        <div>
          {data.data?.isEquityPartlyPaid && data.node.level > 0 && (
            <div className="w-16 px-1 mt-2 mb-2 text-red-500 border border-red-500 rounded-sm font-xxs">
              Partly Paid
            </div>
          )}
        </div>
      </div>
    </VStack>
  );
}

export function DebenturesRender(data: any) {
  return (
    <VStack>
      <div className="pt-3 whitespace-nowrap">
        <p className={tableEntryStyle}>
          {formatToReadableNumber({
            value: Number(data.data?.debentures),
            format: data.data.currency,
          })}
        </p>
      </div>
      <div className="pb-1">
        <HStack className="gap-2">
          {data.data?.isDebtPartlyPaid && data.node.level > 0 && (
            <div className="w-16 px-1 mt-2 mb-2 text-red-500 border border-red-500 rounded-sm font-xxs">
              Partly Paid
            </div>
          )}
          {data.data?.isdebt && data.node.level > 0 && (
            <div className="px-1 mb-2 text-red-500 border border-red-500 rounded-sm font-xxs w-fit">
              Debt
            </div>
          )}
        </HStack>
      </div>
    </VStack>
  );
}

export function RoundsRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-3 whitespace-nowrap">
      {data.node.level === 0 ? (
        data.node.key &&
        data.data?.roundIdentifiers[
          data.node.key.toLowerCase().split(" ").join("")
        ].length > 0 ? (
          <RoundTag
            roundNames={
              data.data?.roundIdentifiers[
                data.node.key.toLowerCase().split(" ").join("")
              ] || []
            }
            navigate={data.data.cin ? () => {} : onClickNavigate}
          />
        ) : (
          <p></p>
        )
      ) : (
        <RoundTag
          roundNames={data.data.rounds || []}
          navigate={data.data.cin ? () => {} : onClickNavigate}
        />
      )}
    </div>
  );
}

export function PreferenceShareRender(data: any) {
  return (
    <VStack>
      <div className="pt-3 whitespace-nowrap">
        <p className={tableEntryStyle}>
          {formatToReadableNumber({
            value: Number(data.data?.prefShares),
            format: data.data.currency,
          })}
        </p>
      </div>
      <div>
        {data.data?.isPreferencePartlyPaid && data.node.level > 0 && (
          <div className="px-1 mb-2 text-red-500 border border-red-500 rounded-sm font-xxs w-fit ">
            Partly Paid
          </div>
        )}
      </div>
    </VStack>
  );
}

export function AmountInvestedRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function TotalInvestedAmountRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.totalAmount,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function FdbSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.fdbShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function FdbPercentageRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.fdbPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function WarrantsRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Number(data.data?.warrants),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NotesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.notes,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
