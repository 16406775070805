import { Icon } from "@iconify/react";
import { tableEntryStyle } from "../../components/TableComponent";
import { HStack, VStack } from "../../components/utils";
import { formatDisplayDate } from "../../utils/date";
import { getTagsColor } from "./DirectorAgComponent";

export function DirectorNamesRender(data: any) {
  return (
    <div className="flex flex-wrap gap-2 mb-2">
      {data.data.directorName?.map((tag: any, index: number) => (
        <div
          key={index}
          className={`text-xs text-gray-600 text-center px-3 py-1 align-middle font-semibold leading-5 border border-1 border-gray-600 rounded-md`}
        >
          <HStack>
            <Icon
              icon="si:user-fill"
              width={18}
              height={18}
              className={`mr-1 border-transparent bg-transparent`}
            />
            {tag.name}
          </HStack>
        </div>
      ))}
    </div>
  );
}

export function DateOfMeetingRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.dateOfMeeting)}
      </p>
    </div>
  );
}
export function DirectorAttendedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.noOfDirectorAttended}</p>
    </div>
  );
}
export function NameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={"font-bold"}>{data.data.name}</p>
    </div>
  );
}

export function TagsRender(data: any) {
  return (
    <div className="flex flex-nowrap items-center gap-2 overflow-x-auto scrollbar-hide pt-2">
      {data.data.tags?.map((tag: string, index: number) => (
        <div
          key={index}
          className={`${getTagsColor(
            tag,
            index
          )} text-xs text-center px-3 font-semibold leading-5 rounded-md`}
        >
          {tag}
        </div>
      ))}
    </div>
  );
}
