import { Navigate, useRoutes } from "react-router-dom";
import { useAuthStore } from "./store";
import SecurityPage from "./pages/securities/SecurityPage";
import AddNewSecurity from "./pages/securities/AddNewSecurity";
import { RolesManager, UserManager } from "./pages/RolesManager";
import ShareholdersOverviewPage from "./pages/shareholders/ShareholdersOverviewPage";
import IndividualShareholder from "./pages/shareholders/IndividualShareholder";
import MainLayout from "./layouts/MainLayout";
import RoundsBuyback from "./pages/rounds/RoundsBuyback";
import RoundsSecondary from "./pages/rounds/RoundsSecondary";
import RoundsBonus from "./pages/rounds/RoundsBonus";
import RoundsAllTransactions from "./pages/rounds/RoundsAllTransactions";
import RoundsOverview from "./pages/rounds/RoundsOverview";
import ResetPasswordPage from "./pages/captableLogin/ResetPasswordPage";
import ForgotPasswordPage from "./pages/captableLogin/ForgotPasswordPage";
import CaptableLandingPage from "./pages/dashboardPage/CaptableLandingPage";
import RoundsSplit from "./pages/rounds/RoundsSplit";
import CaptableLoginPage from "./pages/captableLogin/LoginPage";
import AllTermsheetPage from "./pages/termsheet/AllTermSheetPage";
import IndividualTermSheetPage from "./pages/termsheet/IndividualTermSheetPage";
import TransactionRoundTemplate from "./pages/newQuickTransaction/TransactionRoundTemplate";
import TransactionSecondaryTemplate from "./pages/newQuickTransaction/TransactionSecondaryTemplate";
import TransactionBonusTemplate from "./pages/newQuickTransaction/TransactionBonusTemplate";
import TransactionBuybackTemplate from "./pages/newQuickTransaction/TransactionBuybackTemplate";
import TransactionSplitTemplate from "./pages/newQuickTransaction/TransactionSplitTemplate";
import CompanyProfileCaptable from "./pages/companyProfile/CompanyProfileCaptable";
import PageNotFound404 from "./pages/PageNotFound404";
import DashboardMainPage from "./pages/dashboardPage/DashboardMainPage";
import RedirectPage from "./components/shared/RedirectPage";
import CaptableSignUpPage from "./pages/captableLogin/SignUpPage";
import OnBoardCompanyPage from "./pages/captableLogin/OnboardingPage";
import DocumentVoultView from "./pages/documents/DocumentVoultView";
import AllRightsMainPage from "./pages/shareHolderRights/AllRightsMainPage";
import AddNewCompanyRights from "./pages/shareHolderRights/AddNewCompanyRights";
import DragAndDrop from "./pages/shareHolderRights/DragAndDrop";
import DashboardRedirectPage from "./pages/dashboardPage/DashboardRedirectPage";
import RightsArticle from "./pages/shareHolderRights/RightsArticle";
import UserProfilePage from "./pages/userProfile/UserProfilePage";
import RoundCreationTemplate from "./pages/modeling/RoundCreationTemplate";
import SecurityBuilder from "./pages/modeling/SecurityBuilder";
import Type2CompanyPage from "./pages/t2Company/Type2Company";
import Type3CompanyPage from "./pages/t3Company/Type3Company";
import IndividualSecurityTypePage from "./pages/securities/IndividualSecurityTypePage";
import DesignPage from "./components/shared/DesignPage";
import RoundsEsopExercise from "./pages/rounds/RoundsEsopExercise";
import AgGridFinancialSettingsTable from "./pages/finance/FinancialSettings";
import ModelingDashboardPage from "./pages/newModeling/ModelingDashboard";
import RoundModelTemplate from "./pages/newModeling/ModelingPage";
import ReportsPage from "./pages/reports/ReportPage";
import FinanceWrapper from "./pages/finance/FinanceWrapper";
import CustomTable from "./pages/excelTable/UploadExcel";
import EmailPage from "./pages/captableLogin/EmailPage";
import ShareMainLayout from "./pages/captableLogin/ShareMainLayout";
import { useShareAuthStore } from "./store/useShareAuthStore";
import SharedPage from "./pages/share/SharedPage";
import DeniedPage from "./pages/captableLogin/DeniedPage";
import DisabledPage from "./pages/captableLogin/DisabledPage";
import NonPricedRoundModelTemplate from "./pages/newModeling/NonPricedRoundModelTemplate";
import { BoardModuleWrapper } from "./pages/boardModule/BoardModuleWrapper";

export default function Router() {
  const { isAuthenticated } = useAuthStore();
  const { isShareAuthenticated } = useShareAuthStore();
  return useRoutes([
    {
      path: "/login",
      element: <CaptableLoginPage />,
    },
    {
      path: "/customtable",
      element: <CustomTable />,
    },
    {
      path: "/design",
      element: <DesignPage />,
    },
    {
      path: "/signUp",
      element: <CaptableSignUpPage />,
    },
    {
      path: "/resetpassword/",
      element: <ResetPasswordPage />,
    },
    {
      path: "/forgotpassword",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/excelOnboarding",
      element: isAuthenticated ? (
        <OnBoardCompanyPage />
      ) : (
        <Navigate to="/" replace />
      ),
    },
    {
      path: "/rights",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "overview",
          element: <AllRightsMainPage />,
        },
        {
          path: "/rights/article",
          element: <RightsArticle />,
        },
        {
          path: "new",
          element: <AddNewCompanyRights />,
        },
        {
          path: "shareholder",
          element: <DragAndDrop />,
        },
      ],
    },
    {
      path: "/shareholders",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "overview",
          element: <ShareholdersOverviewPage />,
        },
        {
          path: "individual/:id",
          element: <IndividualShareholder />,
        },
      ],
    },
    {
      path: "/landing",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "dashboard",
          element: <DashboardMainPage />,
        },
        {
          path: "redirect",
          element: <RedirectPage />,
        },
      ],
    },
    {
      path: "/termsheet",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "overview",
          element: <AllTermsheetPage />,
        },
        {
          path: "overview/:id",
          element: <IndividualTermSheetPage />,
        },
      ],
    },
    {
      path: "/setting",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "companydetail",
          element: <CompanyProfileCaptable />,
        },
        {
          path: "roles",
          element: <RolesManager />,
        },
        {
          path: "users",
          element: <UserManager />,
        },
        {
          path: "boardModule",
          element: <BoardModuleWrapper />,
        },
      ],
    },
    {
      path: "/security",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "overview",
          element: <SecurityPage />,
        },
        {
          path: "individual/:type",
          element: <IndividualSecurityTypePage />,
        },
        {
          path: "addNewSecurity",
          element: <AddNewSecurity />,
        },
      ],
    },
    {
      path: "/transactions",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "round/:id",
          element: <RoundsOverview />,
        },
        {
          path: "exercise/:id",
          element: <RoundsEsopExercise />,
        },
        {
          path: "buyback/:id",
          element: <RoundsBuyback />,
        },
        {
          path: "secondary/:id",
          element: <RoundsSecondary />,
        },
        {
          path: "bonus/:id",
          element: <RoundsBonus />,
        },
        {
          path: "allTransactions",
          element: <RoundsAllTransactions />,
        },
        {
          path: "split/:id",
          element: <RoundsSplit />,
        },
        {
          path: "round/create",
          element: <TransactionRoundTemplate />,
        },
        {
          path: "round/create/:id",
          element: <TransactionRoundTemplate />,
        },
        {
          path: "secondary/create",
          element: <TransactionSecondaryTemplate />,
        },
        {
          path: "bonus/create",
          element: <TransactionBonusTemplate />,
        },
        {
          path: "buyback/create",
          element: <TransactionBuybackTemplate />,
        },
        {
          path: "split/create",
          element: <TransactionSplitTemplate />,
        },
      ],
    },
    {
      path: "/financials",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "overview", element: <FinanceWrapper /> },
        { path: "settings", element: <AgGridFinancialSettingsTable /> },
      ],
    },
    {
      path: "/settings",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "companyProfile", element: <CompanyProfileCaptable /> },
      ],
    },

    {
      path: "/companyDocuments",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "overview", element: <DocumentVoultView /> }],
    },
    {
      path: "/financials",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        { path: "overview", element: <FinanceWrapper /> },
        { path: "settings", element: <AgGridFinancialSettingsTable /> },
      ],
    },
    {
      path: "/reports",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "overview", element: <ReportsPage /> }],
    },

    {
      path: "/modeling",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "round",
          element: <RoundCreationTemplate />,
        },
      ],
    },
    {
      path: "/404",
      element: <PageNotFound404 />,
    },

    { path: "*", element: <Navigate to="/404" replace /> },
    { path: "/", element: <Navigate to="/landing/dashboard" replace /> },

    {
      path: "/modeling",
      element: <Navigate to="/modeling/round" replace />,
    },
    {
      path: "/landing/dashboard/:id/:token",
      element: <CaptableLandingPage />,
    },
    {
      path: "/landing/redirect/:companyId/:token",
      element: <DashboardRedirectPage />,
    },
    {
      path: "/article/:id/:cin",
      element: <RightsArticle />,
    },
    {
      path: "/inv",
      children: [
        {
          path: "captable/:cId/:token",
          element: <DashboardMainPage />,
        },
        {
          path: "finance/:cId/:token",
          element: <FinanceWrapper />,
        },
        {
          path: "rights/overview/:cId/:token",
          element: <AllRightsMainPage />,
        },
        {
          path: "security/:cId/:token",
          element: <SecurityPage />,
        },
        {
          path: "security/individual/:type/:cId/:token",
          element: <IndividualSecurityTypePage />,
        },
        {
          path: "transaction",
          children: [
            {
              path: "allTransactions/:cId/:token",
              element: <RoundsAllTransactions />,
            },
            {
              path: "round/:id/:cId/:token",
              element: <RoundsOverview />,
            },
            {
              path: "exercise/:id/:cId/:token",
              element: <RoundsEsopExercise />,
            },
            {
              path: "buyback/:id/:cId/:token",
              element: <RoundsBuyback />,
            },
            {
              path: "secondary/:id/:cId/:token",
              element: <RoundsSecondary />,
            },
            {
              path: "bonus/:id/:cId/:token",
              element: <RoundsBonus />,
            },
            {
              path: "split/:id/:cId/:token",
              element: <RoundsSplit />,
            },
          ],
        },
        {
          path: "t2Company/:cin/",
          element: <Type2CompanyPage />,
          children: [
            {
              path: ":token",
              element: <Type2CompanyPage />,
            },
          ],
        },
        {
          path: "t3Company/:cin/",
          element: <Type3CompanyPage />,
          children: [
            {
              path: ":token",
              element: <Type3CompanyPage />,
            },
          ],
        },
        {
          path: "setting",
          children: [
            {
              path: "boardModule/:cId/:token",
              element: <BoardModuleWrapper />,
            },
          ],
        },
      ],
    },
    {
      path: "/denied/:id",
      element: <DeniedPage />,
    },
    {
      path: "/disabled/:id",
      element: <DisabledPage />,
    },
    {
      path: "/share/login/:id/:email/",
      element: <EmailPage />,
    },
    {
      path: "/share/login/:id",
      element: <EmailPage />,
    },
    {
      path: "/share",
      element: <ShareMainLayout />,
      children: [
        {
          path: ":id",
          element: <SharedPage />,
        },
      ],
    },
    {
      path: "/share/login/:id/:email/:otp",
      element: <EmailPage />,
    },
    {
      path: "/modeling",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "overview",
          element: <ModelingDashboardPage />,
        },
        {
          path: "round",
          element: <RoundCreationTemplate />,
        },
        {
          path: "roundmodel",
          element: <RoundModelTemplate />,
        },
        {
          path: "nonpricedroundmodel/:id",
          element: <NonPricedRoundModelTemplate />,
        },
        {
          path: "nonpricedroundmodel",
          element: <NonPricedRoundModelTemplate />,
        },
      ],
    },
    {
      path: "/user",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [{ path: "profile", element: <UserProfilePage /> }],
    },
  ]);
}
