import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  DocumentType,
  TransactionDocumentInput,
} from "../../types/AllTransactionModel";
import { ReactDropzone } from "../../utils/dropzone";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useAuthStore } from "../../store";
import { Input, Label, Required } from "../../components/shared/InputField";
import { HStack, VStack, Error } from "../../components/utils";
import { useAddDirectorDocuments } from "../../queries/directorDetails";
import { queryClient } from "../../queries";

type DocProps = {
  initialValue?: TransactionDocumentInput;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit" | "View";
  id: string;
};

const AddDirectorDocument = ({
  initialValue,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
  id,
}: DocProps) => {
  const companyId = useAuthStore.getState().companyId || "";

  const [loading, setLoading] = useState(false);
  const viewOnly = mode === "View";
  const [files, setFiles] = useState<File[]>();
  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { mutate: uploadDirectorDocument } = useAddDirectorDocuments();

  const initialValues: TransactionDocumentInput = {
    documentType: DocumentType.document,
    cin: initialValue?.cin || "",
    name: initialValue?.name || "",
    key: initialValue?.key || "",
    size: initialValue?.size || 0,
    tags: initialValue?.tags || [],
    eventId: initialValue?.eventId || "",
  };

  const transactionDocSchema = Yup.object({
    documentType: Yup.string()
      .required()
      .label(DocumentType.document as unknown as string),
    name: Yup.string().required().label("Name"),
    key: Yup.string().when(["documentType"], {
      is: (documentType: string) => Number(documentType) !== 0,
      then: Yup.string().required().label("Link"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const formik = useFormik<TransactionDocumentInput>({
    initialValues,
    validationSchema: transactionDocSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);

      const formData = new FormData();

      formData.append("name", values.name);
      formData.append(
        "key",
        values.documentType === 0 ? values.name : values.key
      );
      formData.append("directorId", id || "");
      formData.append(
        "documentType",
        values.documentType === 0 ? "document" : "link"
      );

      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      uploadDirectorDocument(
        { companyId, formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("getDirectorDocuments");
            toast("Document Added Successfully ", {
              type: "success",
              autoClose: 2000,
            });
            onPrimaryAction();
            setLoading(false);
          },
          onError: (e: any) => {
            toast(
              `${e?.response?.data?.errorMessage || "Something went wrong"}`,
              { type: "error", autoClose: 2000 }
            );
            onPrimaryAction();
            setLoading(false);
          },
        }
      );
    },
  });

  const documentTypeList = [
    {
      label: "File",
      value: DocumentType.document,
    },
    {
      label: "Link",
      value: DocumentType.link,
    },
  ];

  const onClickFileFunction = () => {
    fileRef2.current.click();
  };

  return (
    <div>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {"Add Documents"}
          <XMarkIcon
            className="h-5 mt-1 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="px-0 form"
            key="addShareholderProfile"
          >
            <VStack className="w-128 h-64 gap-4">
              <div className="">
                <Label className="text-sm font-normal ">
                  Select Upload Type <Required />
                </Label>
                <Select
                  options={documentTypeList}
                  placeholder="Select Upload Type"
                  classNamePrefix="select2-selection"
                  className={`w-full pt-1.5 ${
                    viewOnly ? "cursor-not-allowed" : ""
                  }`}
                  onChange={(e: any) => {
                    formik.setFieldValue("documentType", e?.value);
                    formik.setFieldValue("name", "");
                    setFiles(undefined);
                  }}
                  name="documentType"
                  value={documentTypeList[formik.values.documentType]}
                />
                {formik.touched.documentType && formik.errors.documentType && (
                  <Error text={formik.errors.documentType} />
                )}
              </div>
              {formik.values.documentType === DocumentType.document && (
                <div className="flex-1 mt-6 ">
                  <Label className="text-sm font-normal mb-1 ">
                    Upload File <Required />
                  </Label>
                  {/* <input
                    ref={fileRef2}
                    onChange={updateFile}
                    multiple={false}
                    type="file"
                    hidden
                  /> */}
                  <ReactDropzone
                    updateFiles={(value: File[]) => {
                      setFiles(value);
                      if (value.length > 0)
                        formik.setFieldValue("name", "selected");
                      else formik.setFieldValue("name", "");
                    }}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <Error text={"Please select a file"} />
                  )}
                </div>
              )}

              {formik.values.documentType === DocumentType.link && (
                <div className="flex-1 mt-4 ">
                  <Label className="text-sm font-normal ">
                    Name
                    <Required />
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter Name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <Error text={formik.errors.name} />
                  )}
                  <Label className="text-sm font-normal mt-2 ">
                    Link
                    <Required />
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter Link"
                    {...formik.getFieldProps("key")}
                  />
                  {formik.touched.key && formik.errors.key && (
                    <Error text={formik.errors.key} />
                  )}
                </div>
              )}
            </VStack>
            <HStack className="justify-between mt-10">
              <SecondaryCTAButton
                event-name="Back Shareholder Profile"
                buttonSize={ButtonSize.SMALL}
                type="reset"
                onClick={onSecondaryAction}
              >
                Back
              </SecondaryCTAButton>
              {!viewOnly && (
                <PrimaryCTAButton
                  event-name="Save Shareholder Profile"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                  loading={loading}
                >
                  Submit
                </PrimaryCTAButton>
              )}
            </HStack>
          </form>
        </>
      </VStack>
    </div>
  );
};

export default AddDirectorDocument;
