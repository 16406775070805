import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  PhoneIcon,
  PlayCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import { Icon } from "@iconify/react";
import { useMatch, useNavigate } from "react-router";
import { handleEventForTracking } from "../amplitudeAnalytics";

const callsToAction = [{ name: "Settings", href: "#", icon: Cog6ToothIcon }];

export default function FlyoutSettings() {
  const [show, setShown] = useState(false);

  const companyDetailMatch = useMatch(
    `/${"/setting/companydetail".split("/")[1]}/*`
  );

  const navigate = useNavigate();

  const routes = [
    {
      name: "Company Details",
      eventName: "companydetail",
      description: "Information about the company",
      onClick: () => {
        handleEventForTracking({ eventName: `Company Details` });
        navigate("/setting/companydetail");
        setShown(false);
      },
      icon: AdjustmentsHorizontalIcon,
    },
    // {
    //   name: "User Management",
    //   description: "coming soon",
    //   onClick: () => {
    //     handleEventForTracking({ eventName: `User Management` });
    //   },
    //   icon: UserGroupIcon,
    // },
    {
      name: "Board Module",
      eventName: "boardModule",
      description: "Directors & Board details",
      onClick: () => {
        handleEventForTracking({ eventName: `Board Module` });
        navigate("/setting/boardModule");
        setShown(false);
      },
      icon: UserGroupIcon,
    },
  ];
  return (
    <Popover className="relative">
      <Popover.Button
        onClick={() => setShown(!show)}
        onMouseEnter={() => {
          handleEventForTracking({ eventName: `Hover: Settings Entered` });
          setShown(true);
        }}
        onMouseLeave={() => {
          handleEventForTracking({ eventName: `Hover: Settings Left` });
          setShown(false);
        }}
        className={`-m-2.5 p-2.5  ${
          companyDetailMatch
            ? "text-primary"
            : "text-gray-400 hover:text-gray-500"
        }`}
      >
        <span className="sr-only">Settings</span>
        <Icon icon="material-symbols:settings-rounded" className={`h-6 w-6 `} />
      </Popover.Button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={() => {
            setShown(true);
          }}
          onMouseLeave={() => {
            setShown(false);
          }}
          className="absolute z-10 mt-5 flex w-screen max-w-max  -right-8 "
        >
          <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
            <div className="bg-gray-50">
              <a
                key={"setting"}
                href={"setting"}
                className="flex items-center justify-center gap-x-1 p-2 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {"Settings"}
              </a>
            </div>
            <div className="p-4  cursor-pointer">
              {routes.map((item) => (
                <div
                  key={item.name}
                  onClick={item.onClick}
                  className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50 mb-2"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon
                      className={`h-6 w-6  group-hover:text-primary ${
                        companyDetailMatch &&
                        item.eventName === companyDetailMatch?.params["*"]
                          ? "text-primary"
                          : "text-gray-600"
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <a
                      className={`font-semibold  ${
                        companyDetailMatch &&
                        item.eventName === companyDetailMatch?.params["*"]
                          ? "text-primary"
                          : "text-gray-600"
                      } group-hover:text-primary`}
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
