import { Icon } from "@iconify/react";
import { DialogProps, Dialog } from "@mui/material";
import { VStack, HStack } from "../utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../pages/quickRound/CTAButtonComponents";

type AlertDialogProps = {
  message?: string;
  error?: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  status?: boolean;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
} & DialogProps;
function AlertDialog({
  message,
  error = false,
  status,
  primaryActionText = "OK",
  secondaryActionText = "Cancel",
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  ...dialogProps
}: AlertDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <VStack className="justify-between p-9 bg-white rounded h-[300px] ">
        <VStack className="items-center justify-start gap-6 grow">
          {!error ? (
            <Icon icon="ep:warning" color="#FFE99B" height={72} />
          ) : (
            <Icon icon="codicon:error" color="#ff0000" height={72} />
          )}
          <p className="text-sm font-normal text-gray-600">{message}</p>
        </VStack>
        <HStack className="justify-between gap-2">
          <SecondaryCTAButton
            event-name="Delete Cancel"
            onClick={() => onSecondaryAction()}
          >
            {secondaryActionText}
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Delete Confirmation"
            className="px-4"
            onClick={() => onPrimaryAction()}
          >
            {!status ? (
              primaryActionText
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={36}
              />
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </Dialog>
  );
}

export default AlertDialog;
