import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import { TextArea } from "../../components/shared/InputField";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import {
  useAddTransactionNotes,
  useGetTransactionNotes,
} from "../../queries/transactionRound";
import { queryClient } from "../../queries";

export function TransactionNotesPopup({
  eventId,
  onSecondaryAction = () => {},
  onPrimaryAction = () => {},
}: {
  eventId: any;
  onSecondaryAction: () => void;
  onPrimaryAction: () => void;
}) {
  const companyId = useAuthStore().companyId ?? "";

  const { data, refetch } = useGetTransactionNotes(companyId, eventId);

  useEffect(() => {
    refetch();
  }, [eventId]);

  const { mutate: uploadTransactionNotes } = useAddTransactionNotes();

  const [notes, setNotes] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNotes(data || "");
  }, [data]);

  function handleAddNotes() {
    uploadTransactionNotes(
      {
        roundNotes: {
          companyId,
          eventId,
          notes: notes || "",
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("addTransactionNotes");
          queryClient.refetchQueries("addTransactionNotes");
          toast("Notes updated Successfully ", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
          setLoading(false);
        },
        onError: (e: any) => {
          toast(
            `${e?.response?.data?.errorMessage || "Something went wrong"}`,
            { type: "error", autoClose: 2000 }
          );
          onPrimaryAction();
          setLoading(false);
        },
      }
    );
  }

  return (
    <div>
      <Box className="px-7 text-lg font-medium border-b py-4 w-200">
        <h6 className="flex justify-between">
          {"Notes"}
          <XMarkIcon
            className="h-5 mt-1 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="px-7 py-6 gap-6 w-200">
        <TextArea
          placeholder="Enter Transaction Notes..."
          className="min-h-[350px]"
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          value={notes}
        />
        <div className=" flex justify-end">
          <PrimaryCTAButton
            event-name="Save Notes"
            className=""
            onClick={() => {
              setLoading(true);
              handleAddNotes();
            }}
          >
            {!loading ? (
              "Save Notes"
            ) : (
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={18}
              />
            )}
          </PrimaryCTAButton>
        </div>
      </VStack>
    </div>
  );
}
