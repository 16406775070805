import { toast } from "react-toastify";

import { useMutation, useQuery } from "react-query";
import { handleEventForTracking } from "../amplitudeAnalytics";
import {
  addDirectorDetails,
  getDirectorDetails,
  getDirectorDocuments,
  getInvDirectorDetails,
  removeDirectorDetails,
  removeDirectorDocument,
  uploadDirectorDocuments,
} from "../api/director";
import { queryClient } from "./client";

export function useGetDirectorDetails() {
  return useQuery({
    queryKey: "getDirectorDetails",
    queryFn: getDirectorDetails,
  });
}

export function useInvGetDirectorDetails(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvDirectorDetails", cId, token],
    queryFn: getInvDirectorDetails,
  });
}

export function useAddDirectorDetails() {
  return useMutation({
    mutationKey: "addDirectorDetails",
    mutationFn: addDirectorDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addDirectorDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addDirectorDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteDirectorDetails() {
  return useMutation({
    mutationKey: "removeDirectorDetails",
    mutationFn: removeDirectorDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "removeDirectorDetails",
        success: true,
        eventType: "API",
      });
      toast("Director Removed Successfully!", {
        type: "success",
        autoClose: 2000,
      });
    },
    onError: (error: any) => {
      handleEventForTracking({
        eventName: "removeDirectorDetails",
        success: false,
        eventType: "API",
      });
      toast(error, {
        type: "error",
        autoClose: 2000,
      });
    },
  });
}

export function useGetDirectorDocuments(directorId: string, companyId: string) {
  let enabled = false;
  if (directorId && directorId !== "") {
    enabled = true;
  }
  return useQuery({
    queryKey: ["getDirectorDocuments", directorId, companyId],
    queryFn: getDirectorDocuments,
    enabled,
  });
}

export function useAddDirectorDocuments() {
  return useMutation({
    mutationKey: "addDirectorDocuments",
    mutationFn: uploadDirectorDocuments,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "addDirectorDocuments",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addDirectorDocuments",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteDirectorDocument() {
  return useMutation({
    mutationKey: "deleteDirectorDocuments",
    mutationFn: removeDirectorDocument,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "deleteDirectorDocuments",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteDirectorDocuments",
        success: false,
        eventType: "API",
      });
    },
  });
}
