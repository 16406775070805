import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { formatBytes } from "../pages/newQuickTransaction/TransactionDocumentAgComponent";

export function ReactDropzone(props: any) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({});
  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path.split("/").pop()} ({formatBytes(file.size)})
    </li>
  ));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const files1 = acceptedFiles.map(
        (file) => new File([file], file.name, { type: file.type })
      );

      props.updateFiles(files1);
    }
  }, [acceptedFiles]);

  return (
    <section className="container border-dashed border-2 border-gray-400 h-36 hover:border-primary bg-gray-50 rounded-md cursor-pointer">
      <div
        {...getRootProps({
          className: `dropzone h-36 text-center ${
            acceptedFiles.length === 0 ? "pt-8" : ""
          }`,
        })}
      >
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 && (
          <>
            <Icon
              icon="fluent:document-queue-add-24-filled"
              width={50}
              height={50}
              className={`mx-auto border-transparent text-gray-500 bg-transparent pt-2`}
            />
            <h4 className={`text-lg text-gray-600`}>
              {"Drag & drop or click to select files"}
            </h4>
          </>
        )}
        {acceptedFiles.length > 0 && (
          <>
            <aside>
              <h2 className="text-lg text-gray-500">
                {acceptedFiles.length} file(s) selected
              </h2>
              <hr className="h-px bg-gray-200 border-0 dark:bg-gray-300"></hr>
              <ul className="max-h-28 overflow-auto p-2 text-left text-gray-500 scrollbar-hide text-xsm">
                {files}
              </ul>
            </aside>
          </>
        )}
      </div>
    </section>
  );
}
