import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Select from "react-select";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { HStack, VStack, Error } from "../components/utils";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { useAuthStore } from "../store";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import {
  useAddGlobalInvestor,
  useGetGlobalInvestors,
} from "../queries/globalInvestors";
import {
  GlobalInvestorLinkInput,
  LinkGlobalAccountType,
} from "../types/GlobalInvestors";
import { queryClient } from "../queries";

type LinkGlobalInvestorProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit" | "View";
  shareHolderName?: string;
  companyInvestorId?: string;
};

type DropdownType = {
  value: string;
  label: string;
};

const LinkGlobalAccount = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
  shareHolderName,
  companyInvestorId,
}: LinkGlobalInvestorProps) => {
  const companyId = useAuthStore.getState().companyId || "";
  const [loading, setLoading] = useState(false);
  const viewOnly = mode === "View";

  const { data: globalAccount } = useGetGlobalInvestors();
  const { mutate: addGlobalInvestor } = useAddGlobalInvestor();

  const [globalInvestorList, setGlobalInvestorList] = useState<DropdownType[]>([
    {
      label: "Add New",
      value: "Add New",
    },
  ]);
  const [selectedInvestor, setSelectedInvestor] = useState<DropdownType>({
    label: "",
    value: "",
  });

  const intialValues: LinkGlobalAccountType = {
    email: "",
    accountName: "",
    investorName: shareHolderName || "",
    account: "",
    investor: "",
    accountId: "",
    investorId: "",
  };

  const shareholderProfileSchema = Yup.object({
    account: Yup.string().required().label("Global Account Name"),
    accountName: Yup.string().when(["account"], {
      is: (account: string) => account === "Add New",
      then: Yup.string().required().label("Account Name"),
      otherwise: Yup.string().notRequired(),
    }),
    email: Yup.string().when(["account"], {
      is: (account: string) => account === "Add New",
      then: Yup.string().required().email().label("Email"),
      otherwise: Yup.string().notRequired(),
    }),
    investor: Yup.string().when(["account"], {
      is: (account: string) => account !== "Add New",
      then: Yup.string().required().label("Global Investor Name"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const globalAccountList = [
    {
      label: "Add New",
      value: "Add New",
    },
  ];

  if (globalAccount && globalAccount.length > 0) {
    globalAccount.forEach((account) => {
      const exists = globalAccountList.some(
        (item) => item.label === account.name
      );
      if (!exists)
        globalAccountList.push({
          value: account.globalAccountId,
          label: account.name,
        });
    });
  }

  function GetAccountId(name: string) {
    if (globalAccount && globalAccount.length > 0) {
      globalAccount.forEach((account) => {
        if (account.name === name) {
          formik.setFieldValue("accountId", account.globalAccountId);
        }
      });
    }
  }

  function GetInvestorId(name: string) {
    if (globalInvestorList && globalInvestorList.length > 0) {
      globalInvestorList.forEach((investor) => {
        if (investor.label === name) {
          formik.setFieldValue("investorId", investor.value);
        }
      });
    }
  }
  function GetInvestors(name: string) {
    const globalInvestorList: DropdownType[] = [
      {
        label: "Add New",
        value: "Add New",
      },
    ];

    if (name === "") {
      setGlobalInvestorList(globalInvestorList);
    } else if (globalAccount && globalAccount.length > 0) {
      globalAccount.forEach((account) => {
        if (account.name === name) {
          if (account.investors && account.investors.length > 0) {
            account.investors.forEach((investor) => {
              const exists = globalInvestorList.some(
                (item) => item.label === investor.name
              );
              if (!exists) {
                globalInvestorList.push({
                  value: investor.globalInvestorId,
                  label: investor.name,
                });
                setGlobalInvestorList(globalInvestorList);
              }
            });
          } else {
            setGlobalInvestorList(globalInvestorList);
          }
        }
      });
    }
  }

  const formik = useFormik<LinkGlobalAccountType>({
    initialValues: intialValues,
    validationSchema: shareholderProfileSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      const inputValues: GlobalInvestorLinkInput = {
        isNewAccount: values.account === "Add New",
        isNewInvestor: values.investor === "Add New",
        companyInvestorId: companyInvestorId || "",
        accountName:
          values.account === "Add New" ? values.accountName : values.account,
        investorName:
          values.accountId !== "" && values.investorId !== ""
            ? values.investor
            : values.investorName,
        email: values.account === "Add New" ? values.email : "",
        globalInvestorId:
          values.investor !== "Add New" && values.investor !== ""
            ? values.investorId
            : "",
        globalAccountId:
          values.account !== "Add New" && values.account !== ""
            ? values.accountId
            : "",
      };

      addGlobalInvestor(inputValues, {
        onSuccess: () => {
          queryClient.invalidateQueries("getGlobalInvestors");
          queryClient.invalidateQueries("getIndividualShareholderOverview");
          toast("Added Global Investor!", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
          setLoading(false);
        },
        onError: (e: any) => {
          toast(
            `${e?.response?.data?.errorMessage || "Something went wrong"}`,
            { type: "error", autoClose: 2000 }
          );
          onPrimaryAction();
          setLoading(false);
        },
      });
    },
  });

  useEffect(() => {
    if (formik.values.account !== "Add New") {
      GetInvestors(formik.values.account);
      formik.setFieldValue("investor", "");
      setSelectedInvestor({ label: "", value: "" });
      formik.setFieldValue("investorId", "");
      GetAccountId(formik.values.account);
    } else {
      formik.setFieldValue("investor", "Add New");
      formik.setFieldValue("investorId", "");
      formik.setFieldValue("accountId", "");
    }
  }, [formik.values.account]);

  useEffect(() => {
    if (formik.values.investor !== "Add New") {
      GetInvestorId(formik.values.investor);
    } else {
      formik.setFieldValue("investorId", "");
    }
  }, [formik.values.investor]);

  return (
    <div>
      <Box className="text-lg font-medium border-b px-9 py-7">
        <h6 className="flex justify-between">
          {"Link Global Account"}

          <XMarkIcon
            className="h-5 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="px-0 form"
            key="addShareholderProfile"
          >
            <VStack className="w-full h-64 gap-4">
              <HStack className="gap-8">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Global Account</Label>
                  <Select
                    options={globalAccountList}
                    placeholder="Select Global Account"
                    className={`w-72 pt-1.5 ${
                      viewOnly ? "cursor-not-allowed" : ""
                    }`}
                    onChange={(e) => formik.setFieldValue("account", e?.label)}
                    name="account"
                    classNamePrefix="select2-selection"
                  />
                  {formik.touched.account && formik.errors.account && (
                    <Error text={formik.errors.account} />
                  )}
                </div>
                {formik.values.account !== "Add New" && (
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Global Investor
                    </Label>
                    <Select
                      options={globalInvestorList}
                      placeholder="Select Global Investor"
                      className={`w-72 pt-1.5 ${
                        viewOnly ? "cursor-not-allowed" : ""
                      }`}
                      onChange={(e: any) => {
                        setSelectedInvestor(e);
                        formik.setFieldValue("investor", e?.label);
                      }}
                      name="investor"
                      classNamePrefix="select2-selection"
                      value={selectedInvestor}
                    />
                    {formik.touched.investor && formik.errors.investor && (
                      <Error text={formik.errors.investor} />
                    )}
                  </div>
                )}
                {formik.values.account === "Add New" && (
                  <div className="flex-1">
                    <Label className="text-sm font-normal required">
                      Investor Name
                    </Label>
                    <Input
                      type="text"
                      className={`w-72 ${viewOnly ? "cursor-not-allowed" : ""}`}
                      placeholder="Enter Investor Name"
                      {...formik.getFieldProps("investorName")}
                      disabled={true}
                    />
                  </div>
                )}
              </HStack>

              {formik.values.account === "Add New" && (
                <>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal required">
                        Account Name
                      </Label>
                      <Input
                        type="text"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Account Name"
                        {...formik.getFieldProps("accountName")}
                        disabled={viewOnly}
                      />
                      {formik.touched.accountName &&
                        formik.errors.accountName && (
                          <Error text={formik.errors.accountName} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal required">
                        Email
                      </Label>
                      <Input
                        type="email"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Email"
                        {...formik.getFieldProps("email")}
                        disabled={viewOnly}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Error text={formik.errors.email} />
                      )}
                    </div>
                  </HStack>
                </>
              )}
              {formik.values.investor === "Add New" &&
                formik.values.account !== "Add New" && (
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal required">
                        Investor Name
                      </Label>
                      <Input
                        type="text"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Investor Name"
                        {...formik.getFieldProps("investorName")}
                        disabled={true}
                      />
                    </div>
                  </HStack>
                )}
            </VStack>
            <HStack className="justify-between mt-10">
              <SecondaryCTAButton
                event-name="Back Shareholder Profile"
                buttonSize={ButtonSize.SMALL}
                type="reset"
                onClick={onSecondaryAction}
              >
                Back
              </SecondaryCTAButton>
              {!viewOnly && (
                <PrimaryCTAButton
                  event-name="Save Shareholder Profile"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                  loading={loading}
                >
                  Submit
                </PrimaryCTAButton>
              )}
            </HStack>
          </form>
        </>
      </VStack>
    </div>
  );
};

export default LinkGlobalAccount;
