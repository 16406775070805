import { QueryFunctionContext } from "react-query";
import api from "./capTableApi";
import { DirectorDetailModel } from "../pages/companyProfile/CompanyProfileModel";
import { DirectorDetailModel as DirectorDetailsInputModel } from "../types/OnBoardDataModel";

export type DirectorDocTypes = {
  id: string;
  companyId: string;
  documentType: DocumentType;
  cin: string;
  name: string;
  key: string;
  size: string;
  tags: string[];
  updatedAt: string;
  directorId: string;
};
export async function getDirectorDetails(): Promise<DirectorDetailModel> {
  return api.get(`/v1/company/companyDirector/`).then((res) => res.data.data);
}

export async function getInvDirectorDetails(
  context: QueryFunctionContext
): Promise<DirectorDetailModel> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get("v1/company/inv/companyDirector", {
      params: { companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function addDirectorDetails(
  configData: DirectorDetailsInputModel
): Promise<any> {
  return api
    .post("/v1/company/companyDirector/save", configData)
    .then((res) => res.data);
}

export async function removeDirectorDetails({
  id,
}: {
  companyId: string;
  id: string;
}): Promise<string> {
  return api
    .delete(`/v1/company/companyDirector/remove`, {
      data: { id },
    })
    .then((res) => res.data.data.id);
}

export async function getDirectorDocuments(
  context: QueryFunctionContext
): Promise<DirectorDocTypes[]> {
  const directorId = context.queryKey[1] as string;
  const companyId = context.queryKey[2] as string;
  return api
    .get("v1/company/companyDirector/documents", {
      params: { directorId },
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data);
}

export async function uploadDirectorDocuments({
  companyId,
  formData,
}: {
  companyId: string;
  formData: FormData;
}): Promise<string> {
  return api
    .post(`v1/company/companyDirector/document`, formData, {
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data.id);
}

export async function removeDirectorDocument({
  companyId,
  directorId,
  documentId,
}: {
  companyId: string;
  documentId: string;
  directorId: string;
}): Promise<string> {
  return api
    .delete(`v1/company/companyDirector/document`, {
      params: { directorId, documentId },
      headers: {
        companyId,
      },
    })
    .then((res) => res.data.data.id);
}
